<template>
  <svg
    width="66"
    height="57"
    viewBox="0 0 66 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44.7857 30.6842C43.1945 41.4844 33.8318 49.7701 22.519 49.7701C10.0904 49.7604 0 39.7598 0 27.3988C0 15.0571 10.0904 5.04688 22.519 5.04688V27.3988L44.7857 30.6842Z"
      fill="#5FCDBA"
      fill-opacity="0.48"
    />
    <path
      d="M49.4983 22.9207C49.4983 24.0498 49.4115 25.1498 49.257 26.2303L27.1055 22.9207V0.337891C35.4159 0.337891 42.6743 4.90313 46.5448 11.6877C48.4269 14.9973 49.4983 18.8422 49.4983 22.9207Z"
      fill="#5FCDBA"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M38.5809 14.0502C39.0571 13.3088 40.1408 13.3088 40.6171 14.0502L43.4693 18.4908C43.6331 18.7459 43.8867 18.9302 44.18 19.0072L49.2848 20.3476C50.1371 20.5714 50.4718 21.6021 49.914 22.2841L46.572 26.369C46.3801 26.6035 46.2833 26.9019 46.3005 27.2044L46.6032 32.4735C46.6538 33.3534 45.777 33.9903 44.9559 33.6704L40.0382 31.7543C39.7558 31.6442 39.4422 31.6442 39.1598 31.7543L34.2421 33.6704C33.421 33.9903 32.5443 33.3534 32.5948 32.4735L32.8974 27.2044C32.9148 26.9019 32.8179 26.6035 32.6259 26.369L29.2841 22.2841C28.7261 21.6021 29.0609 20.5714 29.9133 20.3476L35.0179 19.0072C35.3112 18.9302 35.5648 18.7459 35.7287 18.4908L38.5809 14.0502ZM37.7649 19.7986L39.599 16.9431L41.4331 19.7986C41.9246 20.564 42.6857 21.1169 43.5654 21.3479L46.8481 22.2098L44.6989 24.8366C44.1229 25.5406 43.8323 26.4351 43.8846 27.3433L44.0791 30.7316L40.9169 29.4995C40.0692 29.1692 39.1288 29.1692 38.2811 29.4995L35.1188 30.7316L35.3134 27.3433C35.3657 26.4351 35.075 25.5406 34.499 24.8366L32.35 22.2098L35.6326 21.3479C36.5123 21.1169 37.2734 20.564 37.7649 19.7986Z"
      fill="#050038"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M57.7492 24.5641C57.7492 31.5288 53.8262 37.5776 48.0692 40.6208V52.821C48.0692 54.0803 46.744 54.8993 45.6175 54.3361L39.5992 51.3269L33.5808 54.3361C32.4545 54.8993 31.1292 54.0803 31.1292 52.821V40.6208C25.3723 37.5776 21.4492 31.5288 21.4492 24.5641C21.4492 14.5401 29.5752 6.41406 39.5992 6.41406C49.6231 6.41406 57.7492 14.5401 57.7492 24.5641ZM39.5992 40.2941C48.2868 40.2941 55.3292 33.2516 55.3292 24.5641C55.3292 15.8766 48.2868 8.83406 39.5992 8.83406C30.9118 8.83406 23.8692 15.8766 23.8692 24.5641C23.8692 33.2516 30.9118 40.2941 39.5992 40.2941ZM39.5992 42.7141C41.7206 42.7141 43.757 42.3501 45.6492 41.6812V51.6463L40.3567 49C39.8799 48.7617 39.3185 48.7617 38.8418 49L33.5492 51.6463V41.6812C35.4414 42.3501 37.4778 42.7141 39.5992 42.7141Z"
      fill="#050038"
    />
  </svg>
</template>

<script>
export default {
  name: "RewardIcon",
};
</script>
