<template>
  <div class="platform-btns mt-4">
    <div
      v-for="platform in PLATFORMS"
      :key="platform.platform_id"
      class="rounded-btn"
      :class="
        selected !== platform.platform_id
          ? 'rounded-btn-outline'
          : ''
      "
      :loading="selected === platform.platform_id && loading"
      @click="$emit('onSelect', platform)"
    >
      {{ platform.name }}
    </div>
  </div>
</template>

<script>
import { ALL_PLATFORM_BASE_OBJECT } from "@/constant/general/GeneralConstant";

const PLATFORMS = [
  ALL_PLATFORM_BASE_OBJECT.shopee,
  ALL_PLATFORM_BASE_OBJECT.tiki,
  ALL_PLATFORM_BASE_OBJECT.lazada,
  ALL_PLATFORM_BASE_OBJECT.sendo,
];

export default {
  name: "PlatformBtns",
  props: {
    selected: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      PLATFORMS,
    };
  },
};
</script>

<style lang="scss" scoped>
.platform-btns {
  width: 100%;
  overflow: auto;

  padding-bottom: 12px;

  display: flex;
  justify-content: flex-start;
}

.rounded-btn {
  width: fit-content;

  padding: 12px 16px;

  margin-right: 12px;

  &:last-child {
    margin-right: 0;
  }

  background: #2e7cf6;
  border-radius: 50px;

  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;

  white-space: nowrap;

  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &-outline {
    background: transparent;
    border: 1px solid #2e7cf6;
    color: #2e7cf6;
  }
}

@media (min-width: 575px) {
  .platform-btns {
    justify-content: center;
    padding-bottom: 0;

    overflow: unset;
  }

  .rounded-btn {
    padding: 16px 24px;

    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }

    font-size: 16px;
    line-height: 20px;
  }
}
</style>
