<template>
  <svg
    width="685"
    height="462"
    viewBox="0 0 685 462"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M477.462 189.844L436.404 372.382C435.325 377.155 429.213 378.594 426.14 374.802L397.896 340.249L394.66 336.293L393.81 335.247L387.435 327.467C385.376 324.95 381.649 324.59 379.132 326.65L341.703 357.672L340.885 358.359L216.273 461.723H39.8477L308.065 239.598C310.55 237.538 310.909 233.877 308.85 231.36L286.163 203.607L285.281 202.56L277.5 193.048C274.362 189.223 277.076 183.47 282.012 183.47L352.261 183.208L369.554 183.143L396.163 183.045H400.675L411.724 183.012L418.883 182.979L438.202 182.914L454.383 182.849L471.644 182.783C475.468 182.685 478.279 186.183 477.462 189.844Z"
      fill="#55CDB7"
      fill-opacity="0.2"
    />
    <path
      d="M223.825 43.0347L205.094 126.262C204.538 128.779 201.335 129.531 199.7 127.537L182.506 106.518C181.427 105.21 179.465 105.014 178.158 106.093L0 255.942V178.533L145.338 65.8193C146.645 64.7405 146.841 62.8118 145.763 61.5043L131.968 44.6365C130.333 42.6424 131.739 39.635 134.321 39.6023L220.785 39.2754C222.779 39.3081 224.25 41.1387 223.825 43.0347Z"
      fill="#DEFFFB"
    />
    <path
      d="M562.488 334.43C560.821 332.469 560.462 329.789 561.54 327.435C562.619 325.081 564.907 323.643 567.49 323.61L650.815 323.316L676.771 323.218H676.804C678.798 323.218 680.694 324.133 681.936 325.702C683.178 327.271 683.636 329.331 683.211 331.292L659.543 432.859C658.955 435.31 657.157 437.174 654.673 437.762C652.221 438.35 649.769 437.566 648.135 435.605L629.796 413.964C627.115 410.793 622.441 410.336 619.205 412.951L558.206 461.723H559.906L619.858 413.735C622.67 411.512 626.658 411.872 628.979 414.618L647.318 436.291C649.181 438.514 652.123 439.462 654.934 438.775C657.746 438.089 659.903 435.931 660.557 433.087L684.224 331.521C684.747 329.233 684.224 326.879 682.753 325.049C681.315 323.218 679.124 322.172 676.804 322.172H676.771L651.044 322.27L567.49 322.564C564.482 322.564 561.835 324.264 560.592 327.01C559.35 329.723 559.775 332.829 561.704 335.117L574.453 350.187C575.597 351.56 576.153 353.292 575.989 355.058C575.826 356.823 574.943 358.425 573.537 359.569L445.885 461.723H447.585L574.191 360.386C575.826 359.078 576.806 357.248 577.002 355.156C577.199 353.096 576.577 351.069 575.237 349.5L562.488 334.43Z"
      fill="#A0F4FF"
    />
    <path
      d="M283.548 9.26618C283.548 9.26618 288.223 -1.19447 300.089 0.113113C311.922 1.42069 310.517 11.914 316.172 10.9333C321.827 9.95265 329.706 10.9333 331.405 17.4712C333.105 24.0091 340.722 20.4133 340.983 25.9705C341.278 31.5277 332.255 35.4832 321.795 30.547C311.367 25.6436 306.267 34.3717 291.622 32.7699C276.945 31.2008 275.147 29.2395 266.451 30.2202C257.756 31.2008 257.985 24.3034 261.94 19.7268C265.863 15.1503 281.848 15.6079 283.548 9.26618Z"
      fill="#F7F0F0"
    />
    <path
      d="M635.845 185.201C635.845 185.201 627.901 169.085 607.863 171.112C587.791 173.139 590.178 189.287 580.632 187.751C571.087 186.247 557.717 187.751 554.84 197.852C551.964 207.953 539.084 202.396 538.594 210.96C538.103 219.525 553.402 225.573 571.087 218.021C588.772 210.47 597.369 223.905 622.213 221.454C647.057 219.035 650.13 215.995 664.84 217.498C679.551 219.002 679.191 208.378 672.522 201.317C665.788 194.289 638.722 194.943 635.845 185.201Z"
      fill="#F7F0F0"
    />
    <path
      d="M552.355 140.449C552.355 140.449 548.465 132.636 538.625 133.616C528.786 134.597 529.963 142.443 525.255 141.691C520.581 140.972 514.01 141.691 512.604 146.594C511.199 151.498 504.857 148.784 504.628 152.969C504.399 157.12 511.885 160.062 520.581 156.401C529.243 152.74 533.493 159.245 545.686 158.068C557.879 156.891 559.383 155.42 566.608 156.139C573.832 156.859 573.668 151.726 570.367 148.294C567.065 144.829 553.761 145.156 552.355 140.449Z"
      fill="#F7F0F0"
    />
    <path
      d="M618.355 82.7854C618.355 82.7854 613.713 73.4689 601.977 74.6458C590.242 75.8226 591.614 85.1391 586.025 84.2892C580.435 83.4065 572.589 84.2892 570.922 90.1406C569.255 95.992 561.704 92.7557 561.409 97.7246C561.115 102.693 570.072 106.191 580.435 101.811C590.797 97.4303 595.831 105.21 610.346 103.805C624.892 102.399 626.69 100.634 635.288 101.517C643.885 102.399 643.689 96.2535 639.766 92.1673C635.876 88.0485 620.022 88.4407 618.355 82.7854Z"
      fill="#F7F0F0"
    />
    <path
      d="M383.154 461.724H484.459L603.384 363.132C604.593 362.151 606.358 362.315 607.339 363.524L630.712 392.095C632.183 393.893 635.092 393.206 635.615 390.951L650.816 323.316L651.045 322.27L659.61 284.154C660.002 282.389 658.662 280.722 656.864 280.754L545.85 281.179C543.496 281.179 542.189 283.925 543.693 285.756L562.849 309.194C563.829 310.404 563.666 312.136 562.456 313.117L383.154 461.724Z"
      fill="#9DECEC"
    />
  </svg>
</template>

<script>
export default {
  name: "Slide3BackgroundShape",
};
</script>
