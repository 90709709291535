<template>
  <a-modal
    :closable="true"
    :maskClosable="false"
    :footer="null"
    :visible="visible"
    style="top: 20px"
    @cancel="onClickCancel"
    :width="isDesktop ? 1127 : 345"
    wrapClassName="popup-lead-form-wraper"
  >
    <div class="popup-lead-form">
      <div class="background-shape">
        <div class="content">
          <div class="left-content">
            <div class="text-sm md:text-2xl mb-4 md:mb-6 font-semibold">
              Liên hệ với chúng tôi
            </div>
            <div class="text-2xl md:text-4xl mb-4 md:mb-5 font-bold">
              Bạn muốn tìm hiểu và trải nghiệm dùng thử?
            </div>
            <div class="text-sm md:text-2xl mb-9 font-medium">
              Đăng ký để nhận tư vấn ngay!
            </div>
          </div>
          <div class="right-content">
            <SignUpLeadForm
              ref="signUpLeadForm"
              :signupSuccess="signupSuccess"
              @success="$emit('success')"
            />
          </div>
        </div>
      </div>
      <div class="white-background-color-shape"></div>
    </div>
  </a-modal>
</template>

<script>
import { mixinStoreGetterGlobal } from "@/mixin/store/MixinGlobal";
import SignUpLeadForm from "@/views/homePage/components/SignUpLeadForm";

export default {
  name: "PopupLeadForm",
  components: { SignUpLeadForm },
  props: {
    signupSuccess: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [mixinStoreGetterGlobal],
  methods: {
    onClickCancel() {
      this.$emit("close");
    },
  },
  watch: {
    visible(newValue) {
      if (newValue && this.$refs.signUpLeadForm) {
        this.$refs.signUpLeadForm.reset();
      }
    },
  },
};
</script>

<style lang="scss">
.popup-lead-form-wraper {
  .ant-modal-close {
    background: #fff;
    border-radius: 50%;

    width: 32px;
    height: 32px;

    top: -10px;
    right: -10px;

    border: 1px solid #2e7cf6;
    box-shadow: 0px 2px 4px rgba(171, 171, 171, 0.17);

    .ant-modal-close-x {
      width: 100%;
      height: 100%;

      line-height: 32px;
    }
  }
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal {
    border-radius: 8px;
    top: 50px;
  }
  .ant-modal-content {
    border-radius: 8px;
  }
  .popup-lead-form {
    background: #50b8f0;
    border-radius: 8px;
    position: relative;

    .background-shape {
      background-image: url("https://lh3.googleusercontent.com/pw/AM-JKLUwBJWoGcJdNLCth1i6bFfIQ63lAebh0hdLQ2mR7ocojWkGYenElDDL-17zjM_uzk0o8DJgDPhk_nRSnyIFpQI_0EXH33V1SZp9Ic_n0V0qwRZMyBEFkcsf-Qe5KJKzxlk2eqB3bJMkvu5-Pw3YROe_=w2880-h1272-no");
      background-size: cover;
      background-position: 0 -50px;

      height: 100%;

      position: relative;
      z-index: 1;
    }

    .white-background-color-shape {
      background-color: #fff;
      height: 250px;
      border-radius: 0 0 8px 8px;
      width: 100%;

      position: absolute;
      bottom: 0;

      z-index: 0;
    }

    .content {
      max-width: 1200px;
      margin: auto;

      padding: 14px 16px;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .left-content {
        font-family: "Montserrat";
        color: #fff;
        max-width: 480px;
      }

      .right-content {
        display: flex;
        align-items: center;
      }
    }
  }
}

@media (min-width: 575px) {
  .popup-lead-form-wraper {
    .ant-modal {
      border-radius: 24px;
      top: 100px;
    }
    .ant-modal-content {
      border-radius: 24px;
    }
    .popup-lead-form {
      border-radius: 24px;
      .background-shape {
        background-position: 0 -50px;
      }

      .white-background-color-shape {
        height: 250px;
        border-radius: 0 0 24px 24px;
      }

      .content {
        max-width: 1200px;
        margin: auto;

        padding: 40px;

        flex-direction: row;

        .left-content {
          max-width: 480px;
        }
      }
    }
  }
}
</style>
