<template>
  <svg
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="60"
      cy="59.6685"
      rx="60"
      ry="59.6685"
      fill="#27BEFF"
      fill-opacity="0.5"
    />
    <path
      d="M60.0018 107.404C86.5481 107.404 108.068 85.8841 108.068 59.3378C108.068 32.7915 86.5481 11.2715 60.0018 11.2715C33.4556 11.2715 11.9355 32.7915 11.9355 59.3378C11.9355 85.8841 33.4556 107.404 60.0018 107.404Z"
      fill="#27BEFF"
    />
    <path
      d="M52.377 81.6266L74.9134 104.088C93.5785 99.1275 107.405 82.1778 107.405 61.9208C107.405 61.5074 107.405 61.094 107.405 60.6806L89.7072 44.4199L52.377 81.6266Z"
      fill="#138EE7"
    />
    <path
      d="M61.1259 69.346C63.0941 71.3141 63.0941 74.6882 61.1259 76.6564L57.049 80.7333C55.0808 82.7015 51.7068 82.7015 49.7386 80.7333L31.8843 62.7385C29.9162 60.7703 29.9162 57.3963 31.8843 55.4281L35.9613 51.3511C37.9295 49.383 41.3035 49.383 43.2717 51.3511L61.1259 69.346Z"
      fill="white"
    />
    <path
      d="M76.7309 38.2769C78.6991 36.3087 82.0731 36.3087 84.0413 38.2769L88.1183 42.3539C90.0864 44.3221 90.0864 47.6961 88.1183 49.6643L57.1897 80.4523C55.2215 82.4205 51.8474 82.4205 49.8793 80.4523L45.8023 76.3753C43.8341 74.4072 43.8341 71.0331 45.8023 69.0649L76.7309 38.2769Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "SuccessfullyIcon",
};
</script>
