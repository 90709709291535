<template>
  <div style="width: 100%">
    <div class="chart mb-6">
      <PieChart
        :series-color="['#6B6B6B', '#8E8E8E', '#A7A7A7', '#E2E2E2']"
        :series-title="'Doanh số'"
        :inner-size="'50%'"
        :series-data="seriesData"
        :categories-name="categoriesName"
        :chart-height="500"
      />
    </div>
    <div
      class="note-box mt-6"
      v-if="Object.keys(selectedPlatformChartData).length"
    >
      <p class="charting-percent">
        <span>Có Thương hiệu:</span>
        {{
          officialShopTotal
            | compareNumberByPercent(
              selectedPlatformChartData.revenue_total_custom
            )
        }}%
      </p>
      <p class="unknown-percent">
        <span>Không xác định:</span>
        {{
          (selectedPlatformChartData.revenue_total_custom - officialShopTotal)
            | compareNumberByPercent(
              selectedPlatformChartData.revenue_total_custom
            )
        }}%
      </p>
    </div>
    <PlatformBtns
      @onSelect="(platform) => (selectedPlatform = platform.platform_id)"
      :selected="selectedPlatform"
      :loading="loading"
    />
  </div>
</template>

<script>
import PieChart from "@/components/chart/PieChart";
import {
  compareNumberByPercent,
  formatCurrency,
  formatNumberHuman,
} from "@/helper/FormatHelper";
import {
  // getDurationRange,
  getSearchConfig,
} from "@/service/search/SearchService";
import { ALL_PLATFORM_BASE_OBJECT } from "@/constant/general/GeneralConstant";
import axios from "axios";
import moment from "moment";
import { URL_MARKET_BACKEND_API } from "@/config/ConfigEndpoint";

import PlatformBtns from "./PlatformBtns";

const NUMBER_CATEGORIES_DISPLAY = 12;

const DEFAULT_COMPOSITE_COMPARE_LST = [
  [
    {
      first_field: "rating_count",
      second_field: "order_count",
      first_rate: 1,
      second_rate: 0.05,
      compare_condition: ">",
      is_default: true,
    },
  ],
];
const getDurationRange = (durationDay, endDate = null) => {
  if (!endDate) {
    return [null, null];
  } else if (!moment.isMoment(endDate)) {
    const endDateString = moment(endDate, "YYYY MM DD").format("YYYY MM DD");
    endDate = moment(endDateString, "YYYY MM DD");
  }
  const startDate = moment(endDate).subtract(durationDay - 1, "days");
  return [startDate, endDate];
};

const CHART_DATA_TYPE = "brands";
const DURATION_DAY = 30;

const PLATFORMS = [
  ALL_PLATFORM_BASE_OBJECT.shopee,
  ALL_PLATFORM_BASE_OBJECT.tiki,
  ALL_PLATFORM_BASE_OBJECT.lazada,
  ALL_PLATFORM_BASE_OBJECT.sendo,
];

export default {
  name: "BrandChart",
  components: {
    PieChart,
    PlatformBtns,
  },
  data() {
    return {
      dataIndex: "0",
      selectedPlatform: ALL_PLATFORM_BASE_OBJECT.shopee.platform_id,
      chartData: {
        [ALL_PLATFORM_BASE_OBJECT.shopee.platform_id]: {},
        [ALL_PLATFORM_BASE_OBJECT.tiki.platform_id]: {},
        [ALL_PLATFORM_BASE_OBJECT.lazada.platform_id]: {},
        [ALL_PLATFORM_BASE_OBJECT.sendo.platform_id]: {},
      },
      loading: false,
      chartDataKeys: [],
      totalStatisticValues: {},
      searchConfigData: [],
      totalStatisticKeys: [],
      ALL_PLATFORM_BASE_OBJECT,
    };
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    selectedPlatformChartData() {
      return this.chartData[this.selectedPlatform] || {};
    },
    seriesData() {
      if (!this.selectedPlatformChartData) return [];
      return (
        this.selectedPlatformChartData[
          `revenue__by__brands_${DURATION_DAY}d`
        ] || []
      ).map((e) => e.value);
    },
    categoriesName() {
      if (!this.selectedPlatformChartData) return [];
      return (
        this.selectedPlatformChartData[
          `revenue__by__brands_${DURATION_DAY}d`
        ] || []
      ).map((e) => e.key);
    },
    officialShopTotal() {
      if (!this.selectedPlatformChartData) return 0;
      const shops =
        this.selectedPlatformChartData[
          `revenue__by__brands_${DURATION_DAY}d`
        ] || [];

      return shops.reduce((acc, cur) => {
        return acc + cur.value;
      }, 0);
    },
  },
  methods: {
    async fetchData() {
      if (!this.searchConfigData.length) {
        const platform_ids = PLATFORMS.map((platform) => platform.platform_id);
        const res = await getSearchConfig(platform_ids);
        this.searchConfigData = res.platforms;
      }

      const allPlatformChartData = await Promise.all(
        PLATFORMS.map(async (platform) => {
          return await this.fetchBrandChartData(platform.platform_id);
        })
      );
      this.chartData = PLATFORMS.reduce((result, currentValue, index) => {
        result[currentValue.platform_id] = allPlatformChartData[index];
        return result;
      }, {});
    },
    async fetchBrandChartData(platform_id) {
      this.official_types = [];
      this.loading = true;
      try {
        const latestCurrentDate = Math.min(
          ...this.searchConfigData.map((platform) => platform.current_date)
        );

        const [startDate, endDate] = getDurationRange(
          DURATION_DAY,
          moment(`${latestCurrentDate}`, "YYYYMMDD").format("YYYY MM DD")
        );

        const url = `${URL_MARKET_BACKEND_API}/market/widget/beecostvn/search_product`;
        const chartStatistics = [
          `revenue__by__${CHART_DATA_TYPE}_${DURATION_DAY}d`,
          "revenue_total_custom",
        ];

        const statistics = chartStatistics;

        const body = {
          include_query: {
            platforms: [platform_id], // all
            categories_level: 1, // hardcode
            is_remove_bad_product: true, // Lọc sản phẩm ảo/bất thường - hardcode
            official_types: [1, 0], // hardcode
            composite_compare_lst: DEFAULT_COMPOSITE_COMPARE_LST,
            [`order_count_${DURATION_DAY}d_range`]: {
              from: 1,
              is_default_from: true,
            },
          },
          start_date: startDate.format("YYYYMMDD"),
          end_date: endDate.format("YYYYMMDD"),
          durationDay: DURATION_DAY,
          statistics,
        };
        const response = await axios.post(url, body, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        const { aggregations } = response.data.data || {};
        if (!aggregations) return {};

        return chartStatistics.reduce((result, item) => {
          const statistic = aggregations.find(
            (i) => i.aggregation.toLowerCase() === item
          );
          if (statistic.entity) {
            result[item] = statistic.entity.value;
            return result;
          }
          const chartItems = statistic.entities.slice(
            0,
            NUMBER_CATEGORIES_DISPLAY
          );
          result[item] = chartItems;
          return result;
        }, {});
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
  },
  filters: {
    formatCurrency,
    formatNumberHuman,
    compareNumberByPercent,
  },
};
</script>

<style lang="scss" scoped>
.chart-header {
  text-align: center;
  .chart-name {
    font-style: normal;
    font-size: 18px;
    line-height: 21px;
    color: #3b3b3b;
  }
}

.note-box {
  .charting-percent {
    color: #096dd9;
  }
}

.rounded-btn {
  width: fit-content;

  padding: 12px 16px;

  margin-right: 12px;

  &:last-child {
    margin-right: 0;
  }

  background: #2e7cf6;
  border-radius: 50px;

  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;

  white-space: nowrap;

  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &-outline {
    background: transparent;
    border: 1px solid #2e7cf6;
    color: #2e7cf6;
  }
}

.platform-btns {
  width: 100%;
  overflow: auto;

  padding-bottom: 12px;

  display: flex;
  justify-content: flex-start;
}

@media (min-width: 575px) {
  .note-box {
    .charting-percent {
      color: #096dd9;
    }
  }
  .rounded-btn {
    padding: 16px 24px;

    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }

    font-size: 16px;
    line-height: 20px;
  }

  .platform-btns {
    justify-content: center;
    padding-bottom: 0;

    overflow: unset;
  }
}
</style>
