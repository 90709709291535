<template>
  <div>
    <form @submit="handleSubmit" class="form" v-if="!signupSuccess">
      <div class="mb-4">
        <label for="fullName-input">
          Họ và tên <span style="color: #ef4444">*</span>
        </label>
        <a-input
          id="fullName-input"
          name="fullName"
          v-model="formData.fullName"
          class="mt-2"
          @blur="onBlur"
        />
        <div v-if="errors.fullName" class="error-message mt-2">
          {{ errors.fullName }}
        </div>
      </div>
      <div class="flex mb-4 flex-col md:flex-row">
        <div class="mb-4 md:mb-0 md:mr-2">
          <label for="email-input">
            Email <span style="color: #ef4444">*</span>
          </label>
          <a-input
            id="email-input"
            name="email"
            v-model="formData.email"
            class="mt-2"
            @blur="onBlur"
            :class="{ error: !!errors.email }"
          />
          <div v-if="errors.email" class="error-message mt-2">
            {{ errors.email }}
          </div>
        </div>
        <div class="md:ml-2">
          <label for="phone-input">
            Số điện thoại <span style="color: #ef4444">*</span>
          </label>
          <a-input
            id="phone-input"
            name="phone"
            v-model="formData.phone"
            class="mt-2"
            @blur="onBlur"
            :class="{ error: !!errors.phone }"
          />
          <div v-if="errors.phone" class="error-message mt-2">
            {{ errors.phone }}
          </div>
        </div>
      </div>
      <div class="mb-4">
        <label for="company-input">Công ty</label>
        <a-input
          id="company-input"
          name="company"
          v-model="formData.company"
          @blur="onBlur"
          class="mt-2"
        />
      </div>
      <div class="mb-8">
        <label for="content-input">
          Nội dung cần tư vấn
          <span style="color: #ef4444">*</span>
        </label>
        <a-textarea
          id="content-input"
          name="content"
          v-model="formData.content"
          @blur="onBlur"
          class="mt-2"
          rows="3"
        />
        <div v-if="errors.content" class="error-message mt-2">
          {{ errors.content }}
        </div>
      </div>
      <a-button html-type="submit" :loading="loading" class="submit-btn">
        Đăng ký nhận tư vấn
      </a-button>
    </form>
    <div
      class="submited"
      :class="POPUP_STATES.SUCCESSFULLY"
      v-if="signupSuccess"
    >
      <SuccessfullyIcon class="mb-8" />
      <div class="block-title mb-8">Đăng ký thành công</div>
      <p>Cảm ơn bạn!</p>
      <p>
        Chuyên viên sẽ liên hệ với bạn để tư vấn cụ thể phần mềm EcomViet
        Metric.
      </p>
      <p>Hãy lưu ý điện thoại nhé!</p>
    </div>
    <!-- <div
      class="submited"
      :class="POPUP_STATES.FAILURE"
      v-if="popupState === POPUP_STATES.FAILURE"
    >
      <img src="@/assets/img/upgradeAccountPopup/failureIcon.svg" />
      <h3>Thất bại</h3>
      <p class="mb-6">Thông tin chưa được gửi thành công. Vui lòng thử lại.</p>
      <a-button
        type="primary"
        size="large"
        @click="popupState = POPUP_STATES.FORM"
        v-if="countError < MAXIMUM_TRY_TIMES"
      >
        Thử lại
      </a-button>
      <a-button
        class="messenger-button"
        size="large"
        v-else
        @click="contactFacebook"
      >
        <img
          src="@/assets/img/upgradeAccountPopup/messengerIcon.svg"
          class="mr-2"
        />
        Liên hệ hỗ trợ
      </a-button>
    </div> -->
  </div>
</template>

<script>
import { apiCreateLead } from "@/api/lead/ApiLead";
import SuccessfullyIcon from "@/views/homePage/components/icons/SuccessfullyIcon";

const inputLabels = {
  fullName: "Họ và tên",
  email: "Email",
  phone: "Số điện thoại",
  company: "Công ty",
  content: "Nội dung cần tư vấn",
};

const POPUP_STATES = {
  FORM: "form",
  SUCCESSFULLY: "successfully",
  FAILURE: "failure",
};

export default {
  name: "HomePage",
  components: {
    SuccessfullyIcon,
  },
  props: {
    signupSuccess: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const user_profile = localStorage.getItem("user_profile");
    const userProfile = user_profile ? JSON.parse(user_profile) : {};

    return {
      popupState: POPUP_STATES.FORM,
      loading: false,
      formData: {
        fullName: "",
        email: userProfile?.email || "",
        phone: "",
        company: "",
        content: "",
      },
      requiredFields: ["fullName", "email", "phone", "content"],
      errors: {},
      POPUP_STATES,
      userProfile,
    };
  },
  methods: {
    reset() {
      this.popupState = POPUP_STATES.FORM;
      this.formData = {
        fullName: "",
        email: this.userProfile?.email || "",
        phone: "",
        company: "",
        content: "",
      };
      this.errors = {};
    },
    onBlur(event) {
      this.validate(event.target.name);
    },
    validate(fieldName) {
      if (fieldName) {
        if (
          this.requiredFields.includes(fieldName) &&
          !this.formData[fieldName]
        ) {
          this.errors = {
            ...this.errors,
            [fieldName]: `Vui lòng nhập ${inputLabels[fieldName]}`,
          };
          return;
        }

        if (
          fieldName === "email" &&
          !this.formData[fieldName].match(
            /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
          )
        ) {
          this.errors = {
            ...this.errors,
            [fieldName]: `Email không hợp lệ`,
          };
          return;
        }
        this.errors = {
          ...this.errors,
          [fieldName]: "",
        };

        return;
      }

      this.errors = this.requiredFields.reduce((errors, field) => {
        if (!this.formData[field]) {
          errors[field] = `Vui lòng nhập ${inputLabels[field]}`;
        } else if (
          field === "email" &&
          !this.formData[field].match(
            /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
          )
        ) {
          errors[field] = `Email không hợp lệ`;
        }
        return errors;
      }, {});
      return this.errors;
    },
    async handleSubmit(e) {
      e.preventDefault();
      const errs = this.validate() || {};
      if (Object.keys(errs).length) {
        return;
      }

      try {
        this.loading = true;
        const note = `${this.formData.content} from:${window.location.href}`;

        await apiCreateLead({
          name: this.formData.fullName,
          phone: this.formData.phone,
          email: this.formData.email,
          organization_name: this.formData.company,
          note,
          label_init: "Nóng",
          source_name: "EcomViet",
          campaign: "Đăng ký dùng thử",
        });

        this.loading = false;
        this.$emit("success");
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  max-width: 550px;
  padding: 16px;

  background: #ffffff;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.16);
  backdrop-filter: blur(20px);

  border-radius: 16px;

  label {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    color: rgba(5, 0, 56, 0.7);
  }

  input,
  textarea {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 16px;

    background: #fff;
  }

  .ant-input {
    &:hover {
      border-color: #2e7cf6;
    }

    &:focus {
      border-color: rgb(46, 124, 246);
      box-shadow: 0 0 0 2px rgba(46, 124, 246, 0.2);
    }
  }

  input {
    height: 54px;

    padding: 4px 20px;

    border-radius: 54px;
  }

  textarea {
    border-radius: 12px;
    padding: 12px 20px;
  }

  .submit-btn {
    width: 100%;
    height: 70px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-family: "Montserrat";
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    color: #ffffff;

    background: #2770ff;
    border-radius: 64px;

    &:focus,
    &:hover {
      border-color: #2e7cf6;
    }
  }

  .error-message {
    color: #ef4444;
  }
}

.submited {
  width: 100%;
  padding: 32px 12px;

  background: #ffffff;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.16);
  backdrop-filter: blur(20px);

  border-radius: 16px;

  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .block-title {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #050038;
  }

  p {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;

    margin-bottom: 20px;

    text-align: center;

    color: #050038;
  }

  &.failure {
    button {
      border-radius: 8px;

      &.messenger-button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;
        margin: auto;
        box-shadow: 2px 3px 5px rgba(78, 78, 78, 0.08);
        border-radius: 4px;
      }
    }
  }
}

@media (min-width: 575px) {
  .form {
    max-width: 550px;
    padding: 34px 40px;

    border-radius: 16px;

    label {
      font-size: 16px;
      line-height: 20px;
    }
  }
  .submited {
    width: 550px;
    padding: 36px;
    // height: 600px;

    .block-title {
      font-size: 28px;
      line-height: 32px;
    }
  }
}
</style>
