var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"statistics"},[_c('div',{staticClass:"flex"},_vm._l(([
          _vm.ALL_PLATFORM_BASE_OBJECT.shopee,
          _vm.ALL_PLATFORM_BASE_OBJECT.lazada,
        ]),function(platform){return _c('div',{key:platform.platform_id,staticClass:"statistic-item"},[_c('div',[_c('div',{staticClass:"statistic-name"},[_vm._v(_vm._s(platform.name))]),_c('div',{staticClass:"value"},[_c('AnimatedNumber',{attrs:{"number":_vm.totalStatisticValues[_vm.durationDay].current[platform.uniqueName]}})],1),(
              _vm.getDiffPercent(
                _vm.totalStatisticValues[_vm.durationDay],
                platform.uniqueName
              )
            )?_c('div',{staticClass:"diff",class:_vm.getDiffPercent(
                _vm.totalStatisticValues[_vm.durationDay],
                platform.uniqueName
              ) < 0
                ? 'text-danger'
                : ''},[(
                _vm.getDiffPercent(
                  _vm.totalStatisticValues[_vm.durationDay],
                  platform.uniqueName
                ) > 0
              )?_c('span',[_vm._v("+")]):_vm._e(),_vm._v(_vm._s(_vm.getDiffPercent( _vm.totalStatisticValues[_vm.durationDay], platform.uniqueName ))+"% ")]):_vm._e()]),_c('div',{staticClass:"statistic-logo"},[_c('img',{staticClass:"img-platform",attrs:{"src":_vm.getPlatformLogoByProductId(platform.uniqueName)}})])])}),0),_c('div',{staticClass:"flex"},_vm._l(([
          _vm.ALL_PLATFORM_BASE_OBJECT.tiki,
          _vm.ALL_PLATFORM_BASE_OBJECT.sendo,
        ]),function(platform){return _c('div',{key:platform.platform_id,staticClass:"statistic-item"},[_c('div',[_c('div',{staticClass:"statistic-name"},[_vm._v(_vm._s(platform.name))]),_c('div',{staticClass:"value"},[_c('AnimatedNumber',{attrs:{"number":_vm.totalStatisticValues[_vm.durationDay].current[platform.uniqueName]}})],1),(
              _vm.getDiffPercent(
                _vm.totalStatisticValues[_vm.durationDay],
                platform.uniqueName
              )
            )?_c('div',{staticClass:"diff",class:_vm.getDiffPercent(
                _vm.totalStatisticValues[_vm.durationDay],
                platform.uniqueName
              ) < 0
                ? 'text-danger'
                : ''},[(
                _vm.getDiffPercent(
                  _vm.totalStatisticValues[_vm.durationDay],
                  platform.uniqueName
                ) > 0
              )?_c('span',[_vm._v("+")]):_vm._e(),_vm._v(_vm._s(_vm.getDiffPercent( _vm.totalStatisticValues[_vm.durationDay], platform.uniqueName ))+"% ")]):_vm._e()]),_c('div',{staticClass:"statistic-logo"},[_c('img',{staticClass:"img-platform",attrs:{"src":_vm.getPlatformLogoByProductId(platform.uniqueName)}})])])}),0)]),_c('div',{staticClass:"duration-days"},_vm._l((_vm.DURATION_DAYS),function(day){return _c('div',{key:day,staticClass:"duration-day-btn",class:{ active: _vm.durationDay === day },on:{"click":function($event){_vm.durationDay = day}}},[_vm._v(" "+_vm._s(day)+" ngày ")])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }