<template>
  <component :is="isMobile ? 'MobileDefault' : 'div'" class="homepage">
    <div class="homepage__header hidden md:flex">
      <div class="logo flex justify-left">
        <BeeMetricLogoSlogan height="54px" />
      </div>
      <div class="menu-right">
        <div class="menu-right__item" :class="{ active: isActive }">
          <a href="/">
            <span>Trang chủ</span>
          </a>
        </div>
        <div class="menu-right__item">
          <a href="/user" v-if="userProfile && Object.keys(userProfile).length">
            <span>{{ userProfile.full_name }}</span>
          </a>
          <a href="/login" v-else>
            <span>Đăng ký/ đăng nhập</span>
          </a>
        </div>
        <div class="open-signup-form-btn" @click="showPopupLeadForm = true">
          Đăng ký nhận tư vấn
        </div>
      </div>
    </div>
    <div class="homepage__content">
      <div class="block-1">
        <div class="content">
          <div class="left-content">
            <div class="text-heading">
              <span class="line-1"> Phần mềm Big data </span>
              <br />
              <span class="line-2"> Phân tích số liệu E-commerce </span>
            </div>
            <div class="description">
              Kinh doanh Thương mại điện tử thông minh, hiệu quả trên Shopee,
              Lazada, Tiki, Sendo.
            </div>
            <div class="open-signup-form-btn" @click="showPopupLeadForm = true">
              Đăng ký nhận tư vấn
            </div>
          </div>
          <div class="right-content">
            <RevenueStatistics />
          </div>
          <Block1BackgroundShape />
        </div>
      </div>
    </div>
    <div class="block-2">
      <div class="content">
        <div class="col">
          <div class="icon">
            <img src="@/assets/img/homepage/pie-chart-icon.svg" />
          </div>
          <div class="title">Phân tích số liệu miễn phí</div>
          <div>Cải thiện cho Doanh nghiệp:</div>
          <ul class="list">
            <li>Quyết định nhanh hơn, chính xác hơn</li>
            <li>Khả năng dự đoán, thay đổi và thích ứng tốc độ cao</li>
          </ul>
        </div>
        <div class="col">
          <div class="icon">
            <img src="@/assets/img/homepage/index-finger-press-btn-icon.svg" />
          </div>
          <div class="title">Thống kê ngành hàng, thương hiệu, sản phẩm</div>
          <ul class="list">
            <li>
              Ước lượng đúng volume thị trường, sức mua và sức tìm kiếm các dòng
              sản phẩm.
            </li>
            <li>Quyết định số lượng nhập và giá bán ra</li>
          </ul>
        </div>
        <div class="col">
          <div class="icon">
            <img src="@/assets/img/homepage/lock-icon.svg" />
          </div>
          <div class="title">Không giới hạn cùng gói Enterprise</div>
          <ul class="list">
            <li>Không giới hạn tùy chỉnh dữ liệu, APIs</li>
            <li>Phân tích sâu (Deep Overview) cho Doanh nghiệp</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="block-3">
      <div class="content md:pb-12 pb-8">
        <div class="block-title mb-20">Cung cấp dữ liệu lớn nhất về TMĐT</div>
        <Slides @openSignUp="showPopupLeadForm = true" />
        <div
          class="flex md:hidden relative"
          style="flex-direction: column; margin-bottom: 40px; z-index: 8"
        >
          <div
            class="open-signup-form-btn mx-auto mb-6"
            @click="showPopupLeadForm = true"
          >
            Đăng ký nhận tư vấn
          </div>
          <a href="/analytics" class="start-analysis-btn mx-auto mb-10">
            Bắt đầu phân tích
            <svg
              width="8"
              height="10"
              viewBox="0 0 8 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.800781 0.599609L7.40078 5.03261L0.800781 9.39961V0.599609Z"
                fill="#2E7CF6"
              />
            </svg>
          </a>
        </div>
        <div class="block-title md:mt-40">Demo tính năng</div>
      </div>
    </div>
    <div class="block-4">
      <div class="content">
        <a-tabs @activeKey="activeKey" centered>
          <a-tab-pane key="1" forceRender>
            <template #tab>
              <div class="flex items-center">
                <div class="mr-1 tab-icon">
                  <BarChartIcon style="transform: translateY(-3px)" />
                </div>
                Phân tích thị trường
              </div>
            </template>
            <div class="relative image">
              <img
                width="100%"
                style="z-index: 1; position: relative"
                src="https://lh3.googleusercontent.com/pw/AM-JKLXw4re_2Lp73iiyhOtRVS-wZWhZ8-U2Iu3bqywuMnyVOGihF5rX1wGHAJr070qolS_r6Gv6elXEDH6srcWUrjkdNKfd5jOFMZa7BY28EnwABn18rAY88A5_2DJUVMfUGUo3_YMjvLT17x2ndMbYN6xb=w2048-h1438-no"
              />
            </div>
          </a-tab-pane>
          <a-tab-pane key="2" forceRender>
            <template #tab>
              <div class="flex items-center">
                <div class="mr-1 tab-icon">
                  <StoreIcon />
                </div>
                Thống kê ngành hàng
              </div>
            </template>
            <div class="relative image">
              <img
                width="100%"
                style="z-index: 1; position: relative"
                src="https://lh3.googleusercontent.com/pw/AM-JKLUIvP_H22DQrDr_iqyCyCmF1UDJTvPsmPSBB8Jw5l_T9KnW67xl0PGCmboZLhVUt_-JJ-P26Y81FjBBLHgFuQC4HvitpL_dt4n1IyHeS4GScYxnBavNPrvUN1QTOnobu9E4K5R920AjiODNqC7AGvpL=w1886-h1438-no"
              />
            </div>
          </a-tab-pane>
          <a-tab-pane key="3" forceRender>
            <template #tab>
              <div class="flex items-center">
                <div class="mr-1 tab-icon">
                  <BoxProductIcon />
                </div>
                Sản phẩm bán chạy
              </div>
            </template>
            <div class="relative image">
              <img
                width="100%"
                style="z-index: 1; position: relative"
                src="https://lh3.googleusercontent.com/pw/AM-JKLX6NPw_syo0TKBNhDfrMRlRhpr2sHWgetBw3Yfiff6E0s94vxFlqG6B2hFieSXNsoi_iuInnQYg17yzxad3BaDibpW0JWYieGVgcUgcZ_1Mks_vNmRkq2jrkgMMWJjWFD5h-3PBOugGMkZXTNaRKJW_=w1912-h1438-no"
              />
            </div>
          </a-tab-pane>
        </a-tabs>
        <div>
          <BackgroundShape class="background-shapes" />
        </div>
      </div>
    </div>
    <div class="block-5">
      <div class="content">
        <div class="left-content">
          <div class="text">
            Hiệu quả khi sử dụng
            <span class="hidden md:inline"
              >EcomViet <span style="color: #ff7227">Metric</span></span
            >
            <span class="inline md:hidden" style="color: #ff7227">Metric</span>
          </div>
        </div>
        <div class="right-content">
          <div class="reviews">
            <div class="review-item">
              <div class="icon-border">
                <div class="icon">
                  <svg
                    width="66"
                    height="46"
                    viewBox="0 0 66 46"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.5035 17.125C17.359 17.125 20.1503 17.9717 22.5245 19.5582C24.8988 21.1446 26.7493 23.3994 27.842 26.0375C28.9348 28.6756 29.2207 31.5785 28.6636 34.3791C28.1065 37.1797 26.7315 39.7522 24.7124 41.7714C22.6932 43.7905 20.1207 45.1655 17.3201 45.7226C14.5195 46.2797 11.6166 45.9938 8.97851 44.901C6.3404 43.8083 4.08557 41.9578 2.49916 39.5835C0.912745 37.2093 0.066 34.418 0.066 31.5625L0 29.5C0 21.8419 3.04218 14.4974 8.45729 9.08229C13.8724 3.66718 21.2169 0.625 28.875 0.625V8.875C26.1653 8.86772 23.481 9.39768 20.9774 10.4342C18.4738 11.4708 16.2005 12.9934 14.289 14.914C13.5461 15.6555 12.8607 16.4526 12.2389 17.2983C12.9773 17.1828 13.7321 17.1209 14.4994 17.1209L14.5035 17.125ZM51.6285 17.125C54.484 17.125 57.2753 17.9717 59.6496 19.5582C62.0238 21.1446 63.8743 23.3994 64.967 26.0375C66.0598 28.6756 66.3457 31.5785 65.7886 34.3791C65.2315 37.1797 63.8565 39.7522 61.8374 41.7714C59.8182 43.7905 57.2457 45.1655 54.4451 45.7226C51.6445 46.2797 48.7416 45.9938 46.1035 44.901C43.4654 43.8083 41.2106 41.9578 39.6242 39.5835C38.0377 37.2093 37.191 34.418 37.191 31.5625L37.125 29.5C37.125 21.8419 40.1672 14.4974 45.5823 9.08229C50.9974 3.66718 58.3419 0.625 66 0.625V8.875C63.2903 8.86772 60.606 9.39768 58.1024 10.4342C55.5988 11.4708 53.3255 12.9934 51.414 14.914C50.6711 15.6555 49.9857 16.4526 49.3639 17.2983C50.1023 17.1828 50.8571 17.1209 51.6285 17.1209V17.125Z"
                      fill="#050038"
                      fill-opacity="0.8"
                    />
                  </svg>
                </div>
              </div>
              <div class="text">
                <span>50%</span> khách hàng đạt mức tăng trưởng<br />
                <span>gấp đôi</span> trở lên.
              </div>
            </div>
            <div class="review-item">
              <div>
                <div class="icon-border">
                  <div class="icon">
                    <svg
                      width="66"
                      height="46"
                      viewBox="0 0 66 46"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.5035 17.125C17.359 17.125 20.1503 17.9717 22.5245 19.5582C24.8988 21.1446 26.7493 23.3994 27.842 26.0375C28.9348 28.6756 29.2207 31.5785 28.6636 34.3791C28.1065 37.1797 26.7315 39.7522 24.7124 41.7714C22.6932 43.7905 20.1207 45.1655 17.3201 45.7226C14.5195 46.2797 11.6166 45.9938 8.97851 44.901C6.3404 43.8083 4.08557 41.9578 2.49916 39.5835C0.912745 37.2093 0.066 34.418 0.066 31.5625L0 29.5C0 21.8419 3.04218 14.4974 8.45729 9.08229C13.8724 3.66718 21.2169 0.625 28.875 0.625V8.875C26.1653 8.86772 23.481 9.39768 20.9774 10.4342C18.4738 11.4708 16.2005 12.9934 14.289 14.914C13.5461 15.6555 12.8607 16.4526 12.2389 17.2983C12.9773 17.1828 13.7321 17.1209 14.4994 17.1209L14.5035 17.125ZM51.6285 17.125C54.484 17.125 57.2753 17.9717 59.6496 19.5582C62.0238 21.1446 63.8743 23.3994 64.967 26.0375C66.0598 28.6756 66.3457 31.5785 65.7886 34.3791C65.2315 37.1797 63.8565 39.7522 61.8374 41.7714C59.8182 43.7905 57.2457 45.1655 54.4451 45.7226C51.6445 46.2797 48.7416 45.9938 46.1035 44.901C43.4654 43.8083 41.2106 41.9578 39.6242 39.5835C38.0377 37.2093 37.191 34.418 37.191 31.5625L37.125 29.5C37.125 21.8419 40.1672 14.4974 45.5823 9.08229C50.9974 3.66718 58.3419 0.625 66 0.625V8.875C63.2903 8.86772 60.606 9.39768 58.1024 10.4342C55.5988 11.4708 53.3255 12.9934 51.414 14.914C50.6711 15.6555 49.9857 16.4526 49.3639 17.2983C50.1023 17.1828 50.8571 17.1209 51.6285 17.1209V17.125Z"
                        fill="#050038"
                        fill-opacity="0.8"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div class="text">
                Tăng trưởng <span>40%</span><br />
                từ khi dùng Metric.
              </div>
            </div>
          </div>
        </div>
        <div
          class="open-signup-form-btn mb-6"
          @click="showPopupLeadForm = true"
        >
          Đăng ký nhận tư vấn
        </div>
      </div>
    </div>
    <div class="block-6">
      <div class="content">
        <div class="block-title">Tin dùng bởi các khách hàng tiêu biểu</div>
        <div class="brands" v-if="isDesktop">
          <img src="@/assets/img/homepage/sunhouse-logo.svg" />
          <img src="@/assets/img/homepage/thegioididong-logo.svg" />
          <img src="@/assets/img/homepage/vuanem-logo.svg" />
          <img src="@/assets/img/homepage/rangdong-logo.svg" />
          <img src="@/assets/img/homepage/kangaroo-logo.svg" />
          <img src="@/assets/img/homepage/thienlong-logo.svg" />
          <img src="@/assets/img/homepage/polo-man-logo.svg" />
          <img src="@/assets/img/homepage/guardian-logo.svg" />
          <img src="@/assets/img/homepage/gumac-logo.svg" />
          <img src="@/assets/img/homepage/pmax-logo.svg" />
          <img src="@/assets/img/homepage/ecg-logo.svg" />
          <img src="@/assets/img/homepage/dhc-logo.svg" />
          <img src="@/assets/img/homepage/viettel-sale-logo.svg" />
          <img src="@/assets/img/homepage/360-logo.svg" />
          <img src="@/assets/img/homepage/shoptida-logo.svg" />
        </div>
        <div class="brands" v-else>
          <div class="flex">
            <img src="@/assets/img/homepage/sunhouse-logo.svg" />
            <img src="@/assets/img/homepage/thegioididong-logo.svg" />
            <img src="@/assets/img/homepage/vuanem-logo.svg" />
            <img src="@/assets/img/homepage/rangdong-logo.svg" />
            <img src="@/assets/img/homepage/kangaroo-logo.svg" />
          </div>
          <div class="flex">
            <img src="@/assets/img/homepage/thienlong-logo.svg" />
            <img src="@/assets/img/homepage/polo-man-logo.svg" />
            <img src="@/assets/img/homepage/guardian-logo.svg" />
            <img src="@/assets/img/homepage/gumac-logo.svg" />
            <img src="@/assets/img/homepage/pmax-logo.svg" />
          </div>
          <div class="flex">
            <img src="@/assets/img/homepage/ecg-logo.svg" />
            <img src="@/assets/img/homepage/dhc-logo.svg" />
            <img src="@/assets/img/homepage/viettel-sale-logo.svg" />
            <img src="@/assets/img/homepage/360-logo.svg" />
            <img src="@/assets/img/homepage/shoptida-logo.svg" />
          </div>
        </div>
        <div class="block-title">Mạng lưới đối tác</div>
        <div class="investors-icons">
          <div class="flex" style="width: fit-content">
            <div class="icon-border">
              <div class="icon">
                <img
                  src="@/assets/img/homepage/vn-life-logo.svg"
                  width="125px"
                />
              </div>
            </div>
            <div class="icon-border">
              <div class="icon">
                <img
                  src="@/assets/img/homepage/vn-pay-logo.svg"
                  width="127px"
                />
              </div>
            </div>
            <div class="icon-border">
              <div class="icon">
                <img src="@/assets/img/homepage/teko-logo.svg" width="96px" />
              </div>
            </div>
            <div class="icon-border">
              <div class="icon">
                <img
                  src="https://lh3.googleusercontent.com/pw/AM-JKLWDrSA0UANq9yMEThAGG1it-KtXk0ZPl4y9-IO1fK2p4gRINI5XahnGNH37rbSywrx6pbRbP9xlCKRuUSMroDAYt6YdAItx7ii1Iwl-lRgfE7-DmcZ-PrHhGI6fbbgZ8hnp-jC_9TQHTQygfSzlUAjT=w448-h300-no"
                  width="104px"
                />
              </div>
            </div>
          </div>
          <div class="flex pl-12 md:pl-32" style="width: fit-content">
            <div class="icon-border">
              <div class="icon">
                <img
                  src="@/assets/img/homepage/lazada-logo.svg"
                  width="131px"
                />
              </div>
            </div>
            <div class="icon-border">
              <div class="icon">
                <img src="@/assets/img/homepage/shopee-logo.svg" />
              </div>
            </div>
            <div class="icon-border">
              <div class="icon">
                <img
                  src="https://lh3.googleusercontent.com/pw/AM-JKLU1Z8djmCYsgE_KhDj0yhvCjfv_-gx--pFo2lzIpUg0017JLdQk2l3uM43mupR83UPTH4jp50efCHKf4gahfgVH3BtFgr9paB35kyXJ4rEaiAOYgDh32CwLwZSciZYUwBJfUQnApB_1MHYtMZPa2lN_=w384-h260-no"
                  width="96px"
                />
              </div>
            </div>
            <div class="icon-border">
              <div class="icon">
                <img
                  src="https://lh3.googleusercontent.com/pw/AM-JKLWEt73yMNndRxohoKm4RQvf5geXje79yslL-Yo_ym2WUl1e-PsONaONZHwalIxHaEXnK4IPjTpc9OnOk4w82FNf7PjZeb-1nwGqVrMkgS2XNxCdBVGSW5gbIBB2uZq9u1jmLEepgy7DelnPvHyr9AvT=w496-h332-no"
                  width="124px"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="call-to-action-box">
          <div class="mb-6 md:mb-0">Số liệu bán hàng thông minh</div>
          <div class="open-signup-form-btn" @click="showPopupLeadForm = true">
            Đăng ký nhận tư vấn
            <svg
              width="20"
              height="14"
              viewBox="0 0 20 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.832682 6.99935C0.832682 7.18841 0.901661 7.36973 1.02444 7.50342C1.14723 7.63711 1.31376 7.71221 1.4874 7.71221L16.9296 7.71221L12.8088 12.1975C12.7479 12.2638 12.6997 12.3425 12.6667 12.4291C12.6338 12.5157 12.6168 12.6085 12.6168 12.7023C12.6168 12.796 12.6338 12.8888 12.6667 12.9754C12.6997 13.062 12.7479 13.1407 12.8088 13.207C12.8697 13.2732 12.942 13.3258 13.0215 13.3617C13.101 13.3976 13.1863 13.416 13.2724 13.416C13.3585 13.416 13.4437 13.3976 13.5232 13.3617C13.6028 13.3258 13.675 13.2732 13.7359 13.207L18.9737 7.50406C19.0346 7.43784 19.083 7.35917 19.116 7.27257C19.149 7.18596 19.166 7.09312 19.166 6.99935C19.166 6.90558 19.149 6.81274 19.116 6.72613C19.083 6.63953 19.0346 6.56086 18.9737 6.49464L13.7359 0.791739C13.675 0.72546 13.6028 0.672884 13.5232 0.637014C13.4437 0.601145 13.3585 0.582683 13.2724 0.582683C13.1863 0.582683 13.101 0.601145 13.0215 0.637014C12.942 0.672884 12.8697 0.72546 12.8088 0.791739C12.7479 0.858018 12.6997 0.936703 12.6667 1.0233C12.6338 1.1099 12.6168 1.20271 12.6168 1.29645C12.6168 1.39018 12.6338 1.48299 12.6667 1.56959C12.6997 1.65619 12.7479 1.73487 12.8088 1.80115L16.9296 6.28649L1.4874 6.28649C1.31376 6.28649 1.14723 6.36159 1.02444 6.49528C0.901661 6.62897 0.832682 6.81029 0.832682 6.99935Z"
                fill="white"
                stroke="white"
                stroke-width="0.5"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="block-7">
      <div class="background-shape">
        <div class="content">
          <div class="left-content">
            <div class="text-sm md:text-2xl mb-4 md:mb-6 font-semibold">
              Liên hệ với chúng tôi
            </div>
            <div class="text-2xl md:text-4xl mb-4 md:mb-5 font-bold">
              Bạn muốn tìm hiểu và trải nghiệm dùng thử?
            </div>
            <div class="text-sm md:text-2xl mb-9 font-medium">
              Đăng ký để nhận tư vấn ngay!
            </div>
          </div>
          <div class="right-content">
            <SignUpLeadForm
              :signupSuccess="signupSuccess"
              @success="onSignupSuccess"
            />
          </div>
        </div>
      </div>
      <div class="white-background-color-shape"></div>
    </div>
    <BeeFooter v-if="isDesktop" />
    <PopupLeadForm
      :visible="showPopupLeadForm"
      :signupSuccess="signupSuccess"
      @close="showPopupLeadForm = false"
      @success="onSignupSuccess"
    />
  </component>
</template>

<script>
import BeeMetricLogoSlogan from "@/assets/logo/BeeMetricLogoSlogan";
import { mixinStoreGetterGlobal } from "@/mixin/store/MixinGlobal";

import MobileDefault from "@/layout/MobileDefault";

import BackgroundShape from "@/views/homePage/components/shapes/BackgroundShape";
import Block1BackgroundShape from "@/views/homePage/components/shapes/Block1BackgroundShape";

import BarChartIcon from "@/views/homePage/components/icons/BarChartIcon";
import StoreIcon from "@/views/homePage/components/icons/StoreIcon";
import BoxProductIcon from "@/views/homePage/components/icons/BoxProductIcon";

import SignUpLeadForm from "@/views/homePage/components/SignUpLeadForm";

import RevenueStatistics from "@/views/homePage/components/RevenueStatistics";

import Slides from "@/views/homePage/components/Slides/index";
import PopupLeadForm from "@/views/homePage/components/PopupLeadForm";

import BeeFooter from "@/components/partial/footer/BeeFooter";

export default {
  name: "HomePage",
  components: {
    BeeMetricLogoSlogan,
    BackgroundShape,
    Block1BackgroundShape,
    BeeFooter,
    BarChartIcon,
    StoreIcon,
    BoxProductIcon,
    Slides,
    MobileDefault,
    SignUpLeadForm,
    PopupLeadForm,
    RevenueStatistics,
  },
  mixins: [mixinStoreGetterGlobal],
  data() {
    const user_profile = localStorage.getItem("user_profile");
    const userProfile = user_profile ? JSON.parse(user_profile) : {};

    return {
      activeKey: "1",
      showPopupLeadForm: false,
      signupSuccess: false,
      userProfile,
    };
  },
  computed: {
    isActive() {
      return this.$route.path === "/";
    },
  },
  methods: {
    onSignupSuccess() {
      this.signupSuccess = true;
    },
  },
};
</script>

<style lang="scss">
.homepage {
  padding-top: 70px;
  #header {
    position: fixed;
    top: 0;

    width: 100%;
    height: 70px;

    margin: 0;
    padding: 8px 12px;

    background: #fff;
    box-shadow: 0px 4px 4px rgba(98, 98, 98, 0.3);
    z-index: 9;
  }

  .ant-layout-footer {
    color: #050038;
    padding-left: 0 !important;
    position: relative;

    .content {
      max-width: 1200px;
      margin: auto;
    }
  }

  .open-signup-form-btn {
    width: fit-content;

    background: #2e7cf6;
    border-radius: 50px;

    padding: 16px 24px;
    font-size: 14px;
    line-height: 17px;

    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    color: #ffffff;

    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  .start-analysis-btn {
    width: fit-content;

    color: #2e7cf6;

    border-radius: 50px;

    padding: 16px 24px;
    font-size: 14px;

    line-height: 17px;

    font-family: "Montserrat", sans-serif;
    font-weight: 500;

    border: 1px solid transparent;

    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
      border: 1px solid #2e7cf6;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }
  &__header {
    width: 100%;
    height: 68px;
    align-items: center;
    justify-content: space-between;

    padding: 0 16px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(98, 98, 98, 0.03);

    .logo {
      width: 126px;
    }

    .menu-right {
      display: none;
    }
  }

  .ant-tabs-nav-wrap {
    display: flex;
    justify-content: center;
  }

  .statistics {
    margin-bottom: 12px;
    .statistic-item {
      position: relative;
      background: #ffffff;
      box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
      border-radius: 16px;

      width: 165px;
      padding: 16px;
      margin-right: 12px;
      margin-bottom: 12px;

      display: flex;
      justify-content: space-between;
      transition: all 0.3s ease;

      &:hover {
        transform: scale(1.1);
      }
      &:nth-child(2) {
        margin-right: 0;
      }

      .statistic-name {
        font-family: "Montserrat", sans-serif;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: -0.387234px;

        color: #67748e;

        margin-bottom: 8px;
      }

      .value {
        font-family: "Montserrat";
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;

        color: #050038;

        margin-bottom: 8px;
      }

      .diff {
        font-family: "Montserrat";
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;

        letter-spacing: -0.387234px;

        color: #00d662;

        &.text-danger {
          color: #ea0606;
        }
      }

      .statistic-logo {
        width: 34px;
        height: 34px;

        display: flex;
        align-items: center;
        justify-content: center;

        background: linear-gradient(135deg, #0085ff 0%, #d2eefe 100%);
        box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.12),
          0px 2px 4px -1px rgba(0, 0, 0, 0.07);
        border-radius: 6px;
        img {
          width: 20px;
          height: 20px;
          border-radius: 84px;
        }
      }
    }
  }

  .block-title {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;

    letter-spacing: 0.02em;

    text-align: center;

    max-width: 300px;
    margin-left: auto;
    margin-right: auto;

    color: #050038;
  }

  .ant-layout-footer {
    background: #fff;

    font-family: "Montserrat", sans-serif;
  }

  .block-1 {
    background: linear-gradient(
      180deg,
      rgba(115, 180, 241, 0.8) 0%,
      rgba(115, 173, 241, 0.4) 67%,
      rgba(255, 255, 255, 1) 100%
    );

    .background-shape {
      display: none;
    }
    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      position: relative;

      margin: auto;
      padding: 0 16px;

      z-index: 0;

      .left-content {
        padding-top: 38px;
        .text-heading {
          font-family: "Montserrat", sans-serif;
          color: #050038;

          text-align: center;

          margin-bottom: 12px;

          .line-1 {
            font-size: 28px;
            line-height: 34px;
            font-weight: 700;
          }

          .line-2 {
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
          }
        }

        .description {
          font-family: "Montserrat";
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;

          letter-spacing: 0.02em;

          margin: 0 auto 24px;

          color: #050038;

          text-align: center;

          max-width: 250px;
        }

        .open-signup-form-btn {
          margin: 0 auto 38px;
        }
      }
      .right-content {
        padding-top: 0;
        margin: auto;

        .duration-days {
          display: flex;
          justify-content: center;
          margin-bottom: 50px;

          .duration-day-btn {
            font-family: "Montserrat";
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;

            align-items: center;
            text-align: center;

            margin-right: 12px;

            &:nth-child(3) {
              margin-right: 0;
            }
            padding: 12px 16px;

            white-space: nowrap;

            background: rgba(255, 255, 255, 0.5);
            border: 1px solid #2e7cf6;
            border-radius: 50px;

            color: #2e7cf6;

            transition: all 0.3s ease;

            cursor: pointer;

            &.active {
              background: #2e7cf6;
              color: #ffffff;
            }

            &:hover {
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            }
          }
        }
      }
    }
  }

  .block-2 {
    background-color: #fff;
    padding: 40px 0;
    .content {
      max-width: 1200px;
      margin: auto;

      display: flex;
      flex-direction: column;
      align-items: center;
      .col {
        flex: 1;
        max-width: 350px;

        font-family: "Montserrat";
        font-weight: 500;
        font-size: 18px;

        margin-bottom: 32px;

        letter-spacing: 0.02em;

        color: #050038;

        div {
          margin-bottom: 16px;
        }

        ul {
          li {
            margin-bottom: 12px;
          }
        }

        .title {
          margin-bottom: 16px;

          font-weight: 700;
          font-size: 24px;
          text-align: center;
        }

        .icon {
          width: 125px;
          height: 125px;

          display: flex;
          align-items: center;
          justify-content: center;

          margin: 0 auto 24px;

          background: #fcd2b4;
          border-radius: 125px;
        }
      }
    }
  }

  .block-3 {
    padding-top: 20px;
    background: #fff;

    .content {
      max-width: 1200px;
      margin: auto;
    }
  }

  .block-4 {
    background: #f8f6f0;
    padding: 0 0 44px;
    position: relative;
    .content {
      max-width: 1200px;
      margin: auto;

      position: relative;
      z-index: 0;

      .ant-tabs-bar {
        margin-bottom: 42px;

        .ant-tabs-nav-scroll {
          display: flex;
          justify-self: center;
          align-items: center;

          width: 100%;
          white-space: break-spaces;

          .ant-tabs-tab {
            font-family: "Montserrat";

            font-weight: 500;
            font-size: 14px;
            line-height: 17px;

            color: #050038;

            width: 33%;
            margin-right: 0;

            padding: 4px;

            text-align: left;

            &-active {
              color: #bc4505;
            }

            .tab-icon svg {
              transform: translateY(0px);
              width: 18px;
              height: 18px;
            }
          }
        }
      }

      .image {
        img {
          width: 100%;
          margin: auto;
        }
      }

      .background-shapes {
        position: absolute;
        width: 100%;
        top: -183px;
        z-index: -1;
      }
    }
  }

  .block-5 {
    position: relative;
    background-image: url("https://lh3.googleusercontent.com/pw/AM-JKLWDalCAkml7ZnT00LDWDMFx8mhL8aLSODveWvsMDHY0_gtvJzeN3rLh-QX1rc8-2Vd3gdIMenooZNfOaCuVyZRn7KlEyF128G5adRLF_2goJpTYgUFpu-Smnrtb66at6I2PpxYrCGo0WUrZaWNsKeDB=w2880-h1092-no");
    background-size: cover;

    display: flex;
    align-items: center;
    justify-content: center;

    .content {
      max-width: 1200px;
      width: 100%;
      margin: auto;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .left-content {
        padding: 60px 0 32px;

        background: #fff;
        width: 100%;

        .text {
          font-family: "Montserrat";
          font-weight: 600;
          font-size: 28px;
          line-height: 34px;
          letter-spacing: 0.02em;

          color: #050038;

          text-align: center;

          max-width: 300px;
          margin: auto;
          span {
            font-size: 28px;
          }
        }
      }

      .right-content {
        width: 100%;
        .reviews {
          display: flex;
          justify-content: flex-start;
          width: 100%;
          overflow: auto;
          margin-bottom: 24px;

          padding: 16px;

          .review-item {
            flex: 1;
            background: rgba(227, 243, 255, 0.9);
            box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(36px);

            border-radius: 25px;

            display: flex;
            align-items: center;
            justify-content: center;

            margin-right: 16px;
            padding: 21px;

            width: 300px;

            min-height: 121px;

            opacity: 0.9;

            transition: all 0.3s ease;

            &:last-child {
              margin-right: 0;
            }

            .icon-border {
              width: 76px;
              height: 76px;

              border: 1px dashed #64b8e7;
              border-radius: 50%;

              display: flex;
              align-items: center;
              justify-content: center;

              .icon {
                width: 70px;
                height: 70px;

                background: rgba(100, 184, 231, 0.43);
                border-radius: 50%;

                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                  width: 25px;
                  height: 17px;
                }
              }
            }

            .text {
              width: max-content;
              max-width: 167px;
              margin-left: 8px;

              font-family: "Montserrat";
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;

              letter-spacing: 0.02em;

              color: #050038;

              span {
                font-weight: 700;
                font-size: 24px;
              }
            }
          }
        }
      }
    }
  }

  .block-6 {
    position: relative;
    padding: 60px 0;
    background: #fff;

    .content {
      max-width: 1200px;
      margin: auto;
      padding: 16px;

      .brands {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        overflow: auto;

        width: 100%;

        flex-wrap: wrap;

        padding: 16px;

        margin-top: 40px;
        margin-bottom: 60px;

        img {
          margin-right: 24px;
          margin-bottom: 32px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .investors-icons {
        display: flex;
        flex-direction: column;
        margin: auto;

        margin-top: 40px;
        margin-bottom: 60px;

        width: 100%;
        overflow: auto;

        .icon-border {
          width: 95px;
          height: 95px;

          margin-right: 24px;
          margin-bottom: 24px;

          &:last-child {
            margin-right: 0;
          }

          border: 1px dashed #2368d4;
          border-radius: 50%;

          display: flex;
          align-items: center;
          justify-content: center;

          transition: all 0.3s ease;

          &:hover {
            transform: scale(1.5);
          }
          .icon {
            width: 85px;
            height: 85px;

            background: rgba(46, 124, 246, 0.2);
            border-radius: 85px;

            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 80%;
            }
          }
        }
      }

      .call-to-action-box {
        padding: 24px;
        box-shadow: 0px 5px 30px rgba(137, 136, 136, 0.37);
        border-radius: 40px;

        font-family: "Montserrat";
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0.02em;

        text-align: center;

        color: #050038;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .block-7 {
    background: #50b8f0;
    position: relative;

    .background-shape {
      background-image: url("https://lh3.googleusercontent.com/pw/AM-JKLUwBJWoGcJdNLCth1i6bFfIQ63lAebh0hdLQ2mR7ocojWkGYenElDDL-17zjM_uzk0o8DJgDPhk_nRSnyIFpQI_0EXH33V1SZp9Ic_n0V0qwRZMyBEFkcsf-Qe5KJKzxlk2eqB3bJMkvu5-Pw3YROe_=w2880-h1272-no");
      background-size: cover;
      background-position: 0 -50px;

      height: 100%;

      position: relative;
      z-index: 1;
    }

    .white-background-color-shape {
      background-color: #fff;
      height: 250px;
      width: 100%;

      position: absolute;
      bottom: 0;

      z-index: 0;
    }

    .content {
      max-width: 1200px;
      margin: auto;

      padding: 14px 16px;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .left-content {
        font-family: "Montserrat";
        color: #fff;
        max-width: 480px;
      }

      .right-content {
        display: flex;
        align-items: center;
      }
    }
  }

  @media (min-width: 575px) {
    padding-top: 100px;
    &__header {
      width: 100%;
      height: 100px;

      position: fixed;
      top: 0;

      z-index: 9;

      padding: 0 52px;
      box-shadow: 0px 10px 50px rgba(186, 186, 186, 0.3);

      .logo {
        width: 304px;
      }

      .menu-right {
        display: flex;
        align-items: center;
        color: #404040;

        .menu-right__item {
          height: 100%;
          margin-right: 48px;

          display: flex;
          align-items: center;

          a {
            letter-spacing: 0.02em;

            font-size: 16px;

            font-family: "Montserrat", sans-serif;
            color: #404040;
            text-decoration: none;

            transition: 0.3s;
          }

          &.active {
            a {
              color: #2e7cf6;
              font-weight: 700;
            }
          }
        }
      }
    }
    .open-signup-form-btn,
    .start-analysis-btn {
      font-size: 18px;
      line-height: 22px;
    }

    .block-title {
      font-size: 36px;
      line-height: 44px;
      max-width: unset;
    }

    .block-1 {
      .background-shape {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
      }

      .content {
        padding-left: 120px;
        padding-right: 52px;

        flex-direction: row;

        height: 727px;

        .left-content {
          padding-top: 177px;

          max-width: 600px;

          .text-heading {
            margin-bottom: 32px;
            margin-right: 32px;

            text-align: left;

            .line-1 {
              font-size: 52px;
              line-height: 63px;
            }

            .line-2 {
              font-size: 36px;
              line-height: 44px;
            }
          }
          .description {
            font-size: 24px;
            line-height: 32px;

            margin: 0 auto 64px;

            text-align: left;
            max-width: unset;
          }
          .open-signup-form-btn {
            margin: unset;
          }
        }

        .right-content {
          padding-top: 62px;
          .statistics {
            margin-bottom: 36px;

            .statistic-item {
              margin-right: 22px;
              margin-bottom: 27px;
              width: 285px;

              .statistic-name {
                font-size: 14px;
                line-height: 17px;
              }

              .value {
                font-size: 20px;
                line-height: 24px;
              }

              .statistic-logo {
                width: 48px;
                height: 48px;

                box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.12),
                  0px 2px 4px -1px rgba(0, 0, 0, 0.07);
                border-radius: 8px;

                img {
                  width: 28px;
                  height: 28px;
                  border-radius: 84px;
                }
              }
            }
          }

          .duration-days {
            display: flex;
            justify-content: center;
            margin-bottom: 50px;

            .duration-day-btn {
              font-size: 16px;
              line-height: 20px;

              margin: 0 20px;

              &:nth-child(3) {
                margin: 0 20px;
              }
              padding: 16px 24px;
            }
          }
        }
      }
    }

    .block-2 {
      padding: 20px 0;
      .content {
        max-width: 1200px;
        margin: auto;

        flex-direction: row;
        justify-content: space-between;
        align-items: start;

        .col {
          flex: 1;
          max-width: 350px;

          margin-bottom: 0;

          font-family: "Montserrat";
          font-weight: 500;
          font-size: 18px;

          letter-spacing: 0.02em;

          color: #050038;

          div {
            margin-bottom: 16px;
          }

          ul {
            li {
              margin-bottom: 12px;
            }
          }

          .title {
            margin-bottom: 19px;

            font-size: 20px;
            text-align: center;
          }

          .icon {
            width: 125px;
            height: 125px;

            display: flex;
            align-items: center;
            justify-content: center;

            margin: 0 auto 24px;

            background: #fcd2b4;
            border-radius: 125px;
          }
        }

        .statistics {
          margin-bottom: 68px;
        }
      }
    }

    .block-3 {
      padding-top: 88px;

      .content {
        max-width: 1200px;
        margin: auto;
      }
    }

    .block-4 {
      padding: 44px 0;
      .content {
        max-width: 1200px;

        .ant-tabs-bar {
          margin-bottom: 42px;

          .ant-tabs-nav-scroll {
            display: flex;
            justify-self: center;
            align-items: center;

            .ant-tabs-nav {
              margin: auto;
            }

            width: 100%;
            white-space: nowrap;

            .ant-tabs-tab {
              font-size: 20px;
              line-height: 24px;

              width: fit-content;
              margin-right: 32px;

              padding: 24px 4px;

              text-align: center;

              .tab-icon svg {
                transform: translateY(0px);
                width: 32px;
                height: 32px;
              }
            }
          }
        }

        .background-shapes {
          top: 30px;
        }

        .statistics {
          margin-bottom: 68px;
        }

        .image {
          img {
            max-width: 1160px;
          }
        }
      }
    }

    .block-5 {
      background-size: cover;

      align-items: center;
      justify-content: center;

      .content {
        width: 100%;

        justify-content: space-between;

        padding: 40px 0 57px;

        .left-content {
          background: transparent;
          .text {
            font-size: 36px;
            line-height: 44px;

            margin-bottom: 55px;

            max-width: unset;

            white-space: nowrap;
            span {
              font-size: 48px;
            }
          }
        }

        .right-content {
          .reviews {
            justify-content: center;

            width: 100%;

            overflow: unset;

            margin-bottom: 60px;
            padding: 0 16px;

            .review-item {
              border-radius: 25px;

              align-items: center;
              justify-content: center;

              padding: 38px 0 38px 38px;

              max-width: 480px;
              width: 100%;
              min-height: 240px;

              margin-right: 32px;

              &:last-child {
                margin-right: 0;
              }

              &:hover {
                transform: scale(1.07);
              }

              .icon-border {
                width: 139px;
                height: 139px;

                .icon {
                  width: 128px;
                  height: 128px;

                  svg {
                    width: 53px;
                    height: 36px;
                  }
                }
              }

              .text {
                max-width: 270px;
                margin-left: 32px;

                font-size: 20px;
                line-height: 40px;

                span {
                  font-size: 28px;
                }
              }
            }
          }
        }
      }
    }

    .block-6 {
      padding: 120px 0;

      .content {
        max-width: 1200px;
        margin: auto;

        padding: 0;

        .brands {
          flex-direction: row;
          margin-top: 80px;
          margin-bottom: 120px;

          padding: 0;

          justify-content: center;
          align-items: center;
          overflow: unset;
          flex-wrap: wrap;

          width: 100%;

          img {
            margin-right: 50px;

            &:nth-child(5n) {
              margin-right: 0;
            }
          }
        }

        .investors-icons {
          flex-direction: column;
          margin: auto;

          width: 100%;
          overflow: unset;

          margin-top: 80px;
          margin-bottom: 80px;

          .icon-border {
            width: 167px;
            height: 167px;

            margin-right: 105px;
            margin-bottom: 52px;

            transition: all 0.3s ease;

            &:hover {
              transform: scale(1.5);
            }

            .icon {
              width: 155px;
              height: 155px;
            }
          }
        }

        .call-to-action-box {
          flex-direction: row;
          text-align: left;

          padding: 40px 75px;
          box-shadow: 0px 5px 30px rgba(137, 136, 136, 0.37);
          border-radius: 150px;

          font-size: 36px;
          line-height: 44px;
        }
      }
    }

    .block-7 {
      height: 850px;

      .background-shape {
        background-position: 0 -50px;
      }

      .white-background-color-shape {
        height: 250px;
      }

      .content {
        max-width: 1200px;
        margin: auto;

        padding: 92px 0;

        flex-direction: row;

        .left-content {
          max-width: 480px;
        }
      }
    }
  }
}
</style>
