<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.57744 3.61328C6.29142 3.61328 3.61328 6.29142 3.61328 9.57744C3.61328 12.8635 6.29142 15.5416 9.57744 15.5416C12.8635 15.5416 15.5416 12.8635 15.5416 9.57744C15.5416 6.29142 12.8635 3.61328 9.57744 3.61328ZM9.57744 14.624C6.79608 14.624 4.53084 12.3588 4.53084 9.57744C4.53084 6.79608 6.79608 4.53084 9.57744 4.53084C12.0835 4.53084 14.1653 6.3717 14.5552 8.76884L11.4011 12.1294L9.3079 10.0362H6.36597V10.9538H8.92941L11.424 13.4484L14.6011 10.0592C14.3545 12.6169 12.1982 14.624 9.57744 14.624Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-width="0.5"
    />
    <path
      d="M9.11719 23.7988H10.0348V24.7164H9.11719V23.7988Z"
      fill="currentColor"
    />
    <path
      d="M9.11719 25.6328H10.0348V26.5504H9.11719V25.6328Z"
      fill="currentColor"
    />
    <path
      d="M9.11719 27.4688H10.0348V28.3863H9.11719V27.4688Z"
      fill="currentColor"
    />
    <path
      d="M26.5525 29.3056H25.6349V16.4597H21.9647V29.3056H21.0471V18.2948H17.3769V29.3056H16.4593V20.13H12.7891V30.2232H30.2228V13.707H26.5525V29.3056ZM15.5418 29.3056H13.7066V21.0475H15.5418V29.3056ZM22.8823 17.3773H24.7174V29.3056H22.8823V17.3773ZM18.2944 19.2124H20.1296V29.3056H18.2944V19.2124ZM27.4701 14.6246H29.3052V29.3056H27.4701V14.6246Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-width="0.5"
    />
    <path
      d="M5.44727 8.20117H6.36483V9.11873H5.44727V8.20117Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-width="0.5"
    />
    <path
      d="M9.11719 8.20117H10.0348V9.11873H9.11719V8.20117Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-width="0.5"
    />
    <path
      d="M7.2832 8.20117H8.20077V9.11873H7.2832V8.20117Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-width="0.5"
    />
    <path
      d="M12.7891 8.20117H13.7066V9.11873H12.7891V8.20117Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-width="0.5"
    />
    <path
      d="M10.9531 8.20117H11.8707V9.11873H10.9531V8.20117Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-width="0.5"
    />
    <path
      d="M29.3042 9.11785V6.36516H28.3867V9.11785H27.4691V8.20028H26.5515V9.11785H25.634V6.36516H24.7164V9.11785H23.7988V7.28272H22.8813V9.11785H22.1702L18.3049 4.76516L16.7221 6.45118C15.5121 3.70423 12.7652 1.77734 9.57663 1.77734C5.27555 1.77734 1.77734 5.27555 1.77734 9.57663C1.77734 13.2469 4.32358 16.3207 7.7415 17.1465V19.5666C7.4605 19.8189 7.28272 20.1802 7.28272 20.5874V27.9279C7.28272 29.1953 8.30924 30.2218 9.57663 30.2218C10.844 30.2218 11.8705 29.1953 11.8705 27.9279V20.5874C11.8705 20.1802 11.6928 19.8189 11.4118 19.5666V17.1465C14.8297 16.3207 17.3759 13.2411 17.3759 9.57663C17.3759 8.83111 17.2669 8.10853 17.0662 7.42609L18.282 6.13003L21.7573 10.0354H30.2218V9.11785H29.3042ZM10.953 27.9279C10.953 28.6849 10.3336 29.3042 9.57663 29.3042C8.81964 29.3042 8.20028 28.6849 8.20028 27.9279V20.5874C8.20028 20.335 8.40673 20.1286 8.65906 20.1286H10.4942C10.7465 20.1286 10.953 20.335 10.953 20.5874V27.9279ZM10.4942 19.211H8.65906V17.3186C8.96301 17.353 9.26695 17.3759 9.57663 17.3759C9.8863 17.3759 10.1902 17.353 10.4942 17.3186V19.211ZM9.57663 16.4583C5.78021 16.4583 2.69491 13.373 2.69491 9.57663C2.69491 5.78021 5.78021 2.69491 9.57663 2.69491C13.373 2.69491 16.4583 5.78021 16.4583 9.57663C16.4583 13.373 13.373 16.4583 9.57663 16.4583Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-width="0.5"
    />
  </svg>
</template>

<script>
export default {
  name: "BarChartIcon",
};
</script>
