<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.4886 14.1503H27.0549C26.8879 13.5339 26.3351 13.1094 25.7075 13.1036H24.1759V9.32399C24.1817 9.17862 24.1068 9.04488 23.9859 8.96929L13.1839 2.66607C13.0572 2.59048 12.9075 2.59048 12.7809 2.66607L1.97887 8.96929C1.8522 9.04488 1.77734 9.17862 1.77734 9.32399V21.9188C1.77734 22.0642 1.8522 22.1979 1.97887 22.2735L12.7866 28.5709C12.8499 28.6058 12.919 28.6232 12.9881 28.6232C13.063 28.6232 13.1321 28.6058 13.1954 28.5709L18.8786 25.2565V27.6522C18.8786 28.6058 19.6386 29.3792 20.5829 29.3792C20.5887 29.3792 20.5944 29.3792 20.6002 29.3792H28.4886C29.4387 29.3908 30.2103 28.6174 30.2218 27.6638C30.2218 27.658 30.2218 27.658 30.2218 27.6522V15.9005C30.2218 14.9353 29.4445 14.1503 28.4886 14.1503ZM25.719 13.9177C26.0415 13.9177 26.3064 14.1793 26.3064 14.5108V14.5515C26.3064 14.8771 26.0472 15.1446 25.719 15.1446H23.3755C23.0531 15.1446 22.7882 14.8829 22.7882 14.5515V14.5108C22.7882 14.1851 23.0473 13.9177 23.3755 13.9177H25.719ZM12.9881 3.49177L17.4794 6.10842L7.47775 11.9406L2.98652 9.32399L12.9881 3.49177ZM12.6024 27.5126L2.58346 21.6862V10.0276L12.6024 15.8598V27.5126ZM12.9881 15.1504L8.28387 12.4058L18.2797 6.57942L22.984 9.32399L12.9881 15.1504ZM18.8786 15.9005V24.3203L13.4085 27.5126V15.854L23.3698 10.0218V13.0978H23.3813C22.7537 13.1036 22.2009 13.5281 22.0339 14.1444H20.6002C19.6444 14.1503 18.8728 14.9411 18.8786 15.9005ZM29.4157 27.6522C29.4157 28.1581 29.0069 28.5709 28.5002 28.5651C28.4944 28.5651 28.4944 28.5651 28.4886 28.5651H20.6002C20.105 28.5709 19.6962 28.1755 19.6847 27.6696C19.6847 27.6638 19.6847 27.658 19.6847 27.6464V15.9005C19.6789 15.3888 20.0877 14.9643 20.6002 14.9585H22.0512C22.2297 15.5516 22.771 15.9528 23.3813 15.947H25.7075C26.3179 15.9528 26.8649 15.5516 27.0376 14.9585H28.4886C29.0011 14.9643 29.4157 15.383 29.4157 15.9005V27.6522Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-width="0.5"
    />
    <path
      d="M21.0615 20.8599H21.4818C21.7064 20.8599 21.8849 20.6796 21.8849 20.4529C21.8849 20.2261 21.7064 20.0458 21.4818 20.0458H21.4703V18.941H22.5816C22.6334 19.1096 22.7889 19.2201 22.9674 19.2143C23.192 19.2143 23.3705 19.0341 23.3705 18.8073V18.534C23.3705 18.3072 23.1862 18.127 22.9616 18.127H21.0615C20.8369 18.127 20.6584 18.313 20.6642 18.534V20.4529C20.6642 20.6738 20.8427 20.8541 21.0615 20.8599Z"
      fill="currentColor"
    />
    <path
      d="M21.0671 24.5826H22.9673C23.1918 24.5826 23.3703 24.4023 23.3703 24.1755V22.2566C23.3703 22.0299 23.1918 21.8496 22.9673 21.8496H21.0671C20.8426 21.8496 20.6641 22.0299 20.6641 22.2566V24.1755C20.6641 24.3965 20.8426 24.5826 21.0671 24.5826ZM21.4702 22.6637H22.5642V23.7685H21.4702V22.6637Z"
      fill="currentColor"
    />
    <path
      d="M21.4876 27.4892H21.4703V26.3844H22.5586C22.5643 26.6112 22.7486 26.7914 22.9732 26.7856C23.192 26.7798 23.3647 26.6053 23.3705 26.3844V25.9948C23.3705 25.768 23.192 25.5819 22.9616 25.5703H21.0615C20.8369 25.5761 20.6584 25.768 20.6642 25.9948V27.9137C20.6642 28.1288 20.8369 28.3091 21.05 28.3091C21.0557 28.3091 21.0557 28.3091 21.0615 28.3091H21.4818C21.7064 28.3091 21.8849 28.1288 21.8849 27.902C21.8849 27.6753 21.7064 27.4892 21.4876 27.4892Z"
      fill="currentColor"
    />
    <path
      d="M23.8881 18.6278L22.7077 19.8198L22.3507 19.4593C22.1952 19.3023 21.9361 19.3023 21.7807 19.4593C21.6252 19.6163 21.6252 19.8779 21.7807 20.0349L22.4256 20.6862C22.581 20.8432 22.8401 20.8432 22.9956 20.6862L24.4639 19.2034C24.6193 19.0464 24.6193 18.7848 24.4639 18.6278C24.3084 18.4708 24.0493 18.4649 23.8881 18.6278Z"
      fill="currentColor"
    />
    <path
      d="M23.8881 26.1412L22.7077 27.3332L22.3507 26.9727C22.1952 26.8157 21.9361 26.8157 21.7807 26.9727C21.6252 27.1297 21.6252 27.3914 21.7807 27.5484L22.4256 28.1996C22.581 28.3566 22.8401 28.3566 22.9956 28.1996L24.4639 26.7168C24.6193 26.5598 24.6193 26.2982 24.4639 26.1412C24.3084 25.9842 24.0493 25.9842 23.8881 26.1412Z"
      fill="currentColor"
    />
    <path
      d="M28.0284 19.1172H25.4949C25.2703 19.1172 25.0918 19.2974 25.0918 19.5242C25.0918 19.751 25.2703 19.9313 25.4949 19.9313H28.0284C28.2529 19.9313 28.4314 19.751 28.4314 19.5242C28.4314 19.2974 28.2472 19.1172 28.0284 19.1172Z"
      fill="currentColor"
    />
    <path
      d="M28.0284 22.8379H25.4949C25.2703 22.8379 25.0918 23.0181 25.0918 23.2449C25.0918 23.4717 25.2703 23.652 25.4949 23.652H28.0284C28.2529 23.652 28.4314 23.4717 28.4314 23.2449C28.4314 23.0181 28.2472 22.8379 28.0284 22.8379Z"
      fill="currentColor"
    />
    <path
      d="M28.0284 26.5586H25.4949C25.2703 26.5586 25.0918 26.7389 25.0918 26.9656C25.0918 27.1924 25.2703 27.3727 25.4949 27.3727H28.0284C28.2529 27.3727 28.4314 27.1924 28.4314 26.9656C28.4314 26.7389 28.2472 26.5586 28.0284 26.5586Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "BoxProductIcon",
};
</script>
