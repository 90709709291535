<template>
  <svg
    width="684"
    height="462"
    viewBox="0 0 684 462"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M477.054 189.686L436.03 372.069C434.953 376.838 428.845 378.275 425.775 374.486L397.555 339.963L394.321 336.011L393.472 334.965L387.103 327.192C385.045 324.677 381.322 324.318 378.807 326.375L341.409 357.371L340.593 358.057L216.086 461.334H39.8105L307.8 239.397C310.283 237.339 310.642 233.681 308.584 231.166L285.917 203.436L285.035 202.391L277.262 192.887C274.126 189.065 276.837 183.317 281.769 183.317L351.959 183.056L369.237 182.99L395.824 182.892H400.331L411.371 182.86L418.524 182.827L437.827 182.762L453.994 182.696L471.24 182.631C475.061 182.533 477.87 186.028 477.054 189.686Z"
      fill="#6B9FED"
      fill-opacity="0.2"
    />
    <path
      d="M223.635 42.9983L204.92 126.155C204.365 128.67 201.164 129.421 199.531 127.429L182.351 106.427C181.273 105.121 179.313 104.925 178.007 106.003L0 255.724V178.381L145.214 65.7635C146.521 64.6857 146.717 62.7587 145.639 61.4522L131.856 44.5987C130.222 42.6063 131.627 39.6015 134.207 39.5688L220.598 39.2422C222.59 39.2748 224.06 41.1039 223.635 42.9983Z"
      fill="#DEEDFF"
    />
    <path
      d="M562.014 334.149C560.348 332.189 559.989 329.511 561.067 327.159C562.145 324.807 564.431 323.37 567.012 323.338L650.266 323.044L676.2 322.946H676.232C678.225 322.946 680.119 323.86 681.36 325.428C682.602 326.996 683.059 329.053 682.634 331.013L658.987 432.493C658.399 434.943 656.603 436.804 654.12 437.392C651.671 437.98 649.221 437.196 647.588 435.237L629.265 413.615C626.587 410.446 621.916 409.989 618.682 412.602L557.736 461.333H559.434L619.336 413.386C622.145 411.165 626.129 411.524 628.448 414.268L646.772 435.923C648.633 438.144 651.573 439.091 654.382 438.405C657.191 437.719 659.346 435.563 660 432.722L683.647 331.242C684.169 328.955 683.647 326.604 682.177 324.775C680.74 322.946 678.551 321.9 676.232 321.9H676.2L650.495 321.998L567.012 322.292C564.007 322.292 561.361 323.991 560.12 326.734C558.879 329.445 559.303 332.548 561.23 334.834L573.968 349.892C575.112 351.263 575.667 352.994 575.504 354.758C575.34 356.522 574.458 358.122 573.054 359.265L445.51 461.333H447.208L573.707 360.082C575.34 358.776 576.32 356.946 576.516 354.856C576.712 352.798 576.091 350.773 574.752 349.206L562.014 334.149Z"
      fill="#A0C0FF"
    />
    <path
      d="M283.307 9.25831C283.307 9.25831 287.977 -1.19345 299.834 0.113016C311.657 1.41949 310.253 11.9039 315.903 10.9241C321.554 9.9442 329.425 10.9241 331.124 17.4564C332.822 23.9888 340.432 20.396 340.693 25.9485C340.987 31.501 331.973 35.453 321.521 30.5211C311.102 25.6218 306.007 34.3425 291.374 32.7421C276.709 31.1743 274.913 29.2146 266.225 30.1945C257.537 31.1743 257.765 24.2827 261.717 19.7101C265.637 15.1374 281.608 15.5947 283.307 9.25831Z"
      fill="#F7F0F0"
    />
    <path
      d="M635.303 185.042C635.303 185.042 627.366 168.94 607.345 170.965C587.29 172.99 589.675 189.125 580.138 187.59C570.6 186.088 557.242 187.59 554.367 197.683C551.493 207.775 538.624 202.223 538.135 210.78C537.645 219.337 552.93 225.38 570.6 217.835C588.27 210.29 596.86 223.714 621.683 221.264C646.506 218.847 649.576 215.81 664.274 217.312C678.972 218.815 678.613 208.2 671.95 201.145C665.221 194.122 638.177 194.776 635.303 185.042Z"
      fill="#F7F0F0"
    />
    <path
      d="M551.883 140.327C551.883 140.327 547.996 132.521 538.165 133.501C528.334 134.481 529.509 142.32 524.806 141.569C520.135 140.85 513.57 141.569 512.166 146.468C510.762 151.367 504.425 148.656 504.197 152.837C503.968 156.985 511.447 159.924 520.135 156.266C528.791 152.608 533.037 159.108 545.22 157.932C557.403 156.756 558.905 155.286 566.123 156.005C573.341 156.724 573.178 151.596 569.879 148.166C566.581 144.704 553.287 145.031 551.883 140.327Z"
      fill="#F7F0F0"
    />
    <path
      d="M617.826 82.7159C617.826 82.7159 613.188 73.4073 601.462 74.5832C589.737 75.759 591.108 85.0676 585.523 84.2184C579.938 83.3365 572.099 84.2184 570.433 90.0648C568.768 95.9113 561.223 92.6778 560.929 97.6424C560.635 102.607 569.584 106.102 579.938 101.725C590.292 97.3484 595.322 105.122 609.824 103.717C624.358 102.313 626.154 100.549 634.744 101.431C643.335 102.313 643.139 96.1726 639.219 92.0899C635.332 87.9745 619.491 88.3664 617.826 82.7159Z"
      fill="#F7F0F0"
    />
    <path
      d="M382.832 461.332H484.051L602.874 362.824C604.083 361.844 605.847 362.007 606.826 363.216L630.18 391.762C631.649 393.558 634.556 392.873 635.079 390.619L650.267 323.042L650.495 321.997L659.053 283.913C659.444 282.149 658.105 280.483 656.309 280.516L545.39 280.941C543.038 280.941 541.731 283.684 543.234 285.513L562.374 308.932C563.354 310.14 563.19 311.871 561.982 312.851L382.832 461.332Z"
      fill="#98ADF8"
    />
  </svg>
</template>

<script>
export default {
  name: "Slide1BackgroundShape",
};
</script>
