<template>
  <div>
    <div class="statistics">
      <div class="flex">
        <div
          class="statistic-item"
          v-for="platform in [
            ALL_PLATFORM_BASE_OBJECT.shopee,
            ALL_PLATFORM_BASE_OBJECT.lazada,
          ]"
          :key="platform.platform_id"
        >
          <div>
            <div class="statistic-name">{{ platform.name }}</div>
            <div class="value">
              <AnimatedNumber
                :number="
                  totalStatisticValues[durationDay].current[platform.uniqueName]
                "
              />
            </div>
            <div
              class="diff"
              v-if="
                getDiffPercent(
                  totalStatisticValues[durationDay],
                  platform.uniqueName
                )
              "
              :class="
                getDiffPercent(
                  totalStatisticValues[durationDay],
                  platform.uniqueName
                ) < 0
                  ? 'text-danger'
                  : ''
              "
            >
              <span
                v-if="
                  getDiffPercent(
                    totalStatisticValues[durationDay],
                    platform.uniqueName
                  ) > 0
                "
                >+</span
              >{{
                getDiffPercent(
                  totalStatisticValues[durationDay],
                  platform.uniqueName
                )
              }}%
            </div>
          </div>
          <div class="statistic-logo">
            <img
              :src="getPlatformLogoByProductId(platform.uniqueName)"
              class="img-platform"
            />
          </div>
        </div>
      </div>
      <div class="flex">
        <div
          class="statistic-item"
          v-for="platform in [
            ALL_PLATFORM_BASE_OBJECT.tiki,
            ALL_PLATFORM_BASE_OBJECT.sendo,
          ]"
          :key="platform.platform_id"
        >
          <div>
            <div class="statistic-name">{{ platform.name }}</div>
            <div class="value">
              <AnimatedNumber
                :number="
                  totalStatisticValues[durationDay].current[platform.uniqueName]
                "
              />
            </div>
            <div
              class="diff"
              v-if="
                getDiffPercent(
                  totalStatisticValues[durationDay],
                  platform.uniqueName
                )
              "
              :class="
                getDiffPercent(
                  totalStatisticValues[durationDay],
                  platform.uniqueName
                ) < 0
                  ? 'text-danger'
                  : ''
              "
            >
              <span
                v-if="
                  getDiffPercent(
                    totalStatisticValues[durationDay],
                    platform.uniqueName
                  ) > 0
                "
                >+</span
              >{{
                getDiffPercent(
                  totalStatisticValues[durationDay],
                  platform.uniqueName
                )
              }}%
            </div>
          </div>
          <div class="statistic-logo">
            <img
              :src="getPlatformLogoByProductId(platform.uniqueName)"
              class="img-platform"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="duration-days">
      <div
        v-for="day in DURATION_DAYS"
        :key="day"
        class="duration-day-btn"
        :class="{ active: durationDay === day }"
        @click="durationDay = day"
      >
        {{ day }} ngày
      </div>
    </div>
  </div>
</template>

<script>
import { getUrlGPhotoSize } from "@/helper/GPhotoHelper";
import { ALL_PLATFORM_BASE_OBJECT } from "@/constant/general/GeneralConstant";
import { URL_MARKET_BACKEND_API } from "@/config/ConfigEndpoint";
import axios from "axios";
import moment from "moment";
import { getSearchConfig } from "@/service/search/SearchService";
import { formatNumberHuman } from "@/helper/FormatHelper";
import AnimatedNumber from "./AnimatedNumber";

const getDurationRange = (durationDay, endDate = null) => {
  if (!endDate) {
    return [null, null];
  } else if (!moment.isMoment(endDate)) {
    const endDateString = moment(endDate, "YYYY MM DD").format("YYYY MM DD");
    endDate = moment(endDateString, "YYYY MM DD");
  }
  const startDate = moment(endDate).subtract(durationDay - 1, "days");
  return [startDate, endDate];
};

const DEFAULT_COMPOSITE_COMPARE_LST = [
  [
    {
      first_field: "rating_count",
      second_field: "order_count",
      first_rate: 1,
      second_rate: 0.05,
      compare_condition: ">",
      is_default: true,
    },
  ],
];

const PLATFORMS = [
  ALL_PLATFORM_BASE_OBJECT.shopee,
  ALL_PLATFORM_BASE_OBJECT.tiki,
  ALL_PLATFORM_BASE_OBJECT.lazada,
  ALL_PLATFORM_BASE_OBJECT.sendo,
];

const DURATION_DAYS = [30, 90, 180];

export default {
  name: "RevenueStatistics",
  components: {
    AnimatedNumber,
  },
  data() {
    return {
      durationDay: 30,
      totalStatisticValues: {
        30: {
          current: {},
          previous: {},
        },
        90: {
          current: {},
          previous: {},
        },
        180: {
          current: {},
          previous: {},
        },
      },
      searchConfigData: [],
      DURATION_DAYS,
      PLATFORMS,
      ALL_PLATFORM_BASE_OBJECT,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    getDiffPercent(statisticValue, platformUniqueName) {
      const current = statisticValue.current[platformUniqueName];
      const previous = statisticValue.previous[platformUniqueName];
      if (!current && !previous) {
        return 0;
      }

      return (((current - previous) / previous) * 100).toFixed(2);
    },
    async fetchData() {
      DURATION_DAYS.forEach(async (durationDay) => {
        const statisticCurrent = await this.fetchStatisticData(durationDay);
        const statisticPrevious = await this.fetchStatisticData(
          durationDay,
          durationDay
        );

        const current = Object.keys(statisticCurrent).reduce(
          (result, currentPlatform) => {
            result[currentPlatform] = statisticCurrent[currentPlatform].value;
            return result;
          },
          {}
        );
        const previous = Object.keys(statisticPrevious).reduce(
          (result, currentPlatform) => {
            result[currentPlatform] = statisticPrevious[currentPlatform].value;
            return result;
          },
          {}
        );
        this.totalStatisticValues[durationDay] = {
          current,
          previous,
        };
      });
    },
    async fetchStatisticData(durationDay = 30, subtractDay = 0) {
      this.official_types = [];
      this.loading = true;

      const platform_ids = PLATFORMS.map((platform) => platform.platform_id);
      try {
        const url = `${URL_MARKET_BACKEND_API}/market/widget/beecostvn/search_product`;

        if (!Object.keys(this.searchConfigData).length) {
          const res = await getSearchConfig(platform_ids);
          this.searchConfigData = res.platforms;
        }

        const latestCurrentDate = Math.min(
          ...this.searchConfigData.map((platform) => platform.current_date)
        );
        const [startDate, endDate] = getDurationRange(
          durationDay,
          moment(`${latestCurrentDate}`, "YYYYMMDD").format("YYYY MM DD")
        );

        const body = {
          include_query: {
            platforms: platform_ids, // all
            is_remove_bad_product: true, // Lọc sản phẩm ảo/bất thường - hardcode
            composite_compare_lst: DEFAULT_COMPOSITE_COMPARE_LST,
          },
          start_date: startDate.subtract(subtractDay).format("YYYYMMDD"),
          end_date: endDate.subtract(subtractDay).format("YYYYMMDD"),
          statistics: ["revenue_total_custom"],
        };
        const response = await axios.post(url, body, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        const { aggregations } = response.data.data;

        const revenueTotalCustomValues = aggregations.find(
          (i) => i.aggregation.toLowerCase() === "revenue_total_custom"
        )?.entity.platforms;

        return revenueTotalCustomValues.reduce((result, item) => {
          const key = PLATFORMS.find(
            (i) => i.platform_id === item.platform_id
          )?.uniqueName;
          result[key] = { value: item.value };

          return result;
        }, {});
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    getPlatformLogoByProductId(platformName) {
      const platform = ALL_PLATFORM_BASE_OBJECT[platformName];
      return getUrlGPhotoSize(platform.urlLogo);
    },
  },
};
</script>
