<template>
  <svg
    width="24"
    height="23"
    viewBox="0 0 24 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_5766_8087)">
      <path
        d="M14.0012 20.2266V21.0213C14.0012 22.1181 13.1059 23.0057 11.9996 23.0057C10.8934 23.0057 9.99805 22.1181 9.99805 21.0213V20.2266H14.0012Z"
        fill="#BAC0CC"
      />
      <path
        d="M15.6004 20.2261C15.5957 20.663 15.2395 21.0162 14.7988 21.0208H9.20195C8.76133 21.0208 8.40508 20.663 8.40039 20.2261C8.40508 19.7893 8.76133 19.4361 9.20195 19.4314V18.6367H14.8035V19.4314C15.2395 19.4361 15.6004 19.7893 15.6004 20.2261Z"
        fill="#CDD1DA"
      />
      <path
        d="M19.5563 6.71552C19.8188 9.09034 18.9282 11.4512 17.1563 13.0685C15.9141 14.1978 15.2063 15.7873 15.2016 17.4603V18.2411H8.79848V17.4603C8.79848 15.7965 8.09067 14.2071 6.85786 13.0825C4.37348 10.8145 3.6938 7.1942 5.18911 4.19663C6.68442 1.19905 9.99848 -0.469364 13.3266 0.116209C16.6547 0.701782 19.186 3.38798 19.5563 6.71552Z"
        fill="#FFC436"
      />
      <path
        d="M8.39922 17.8457H15.5992C16.0398 17.8457 16.4008 18.1989 16.4008 18.6404C16.4008 19.0773 16.0445 19.4351 15.5992 19.4351H8.39922C7.95859 19.4351 7.59766 19.0819 7.59766 18.6404C7.59766 18.1989 7.95859 17.8457 8.39922 17.8457Z"
        fill="#BAC0CC"
      />
      <path
        d="M23.6012 7.93459H21.2012C20.9809 7.93459 20.8027 7.75799 20.8027 7.53956C20.8027 7.32113 20.9809 7.14453 21.2012 7.14453H23.6012C23.8215 7.14453 23.9996 7.32113 23.9996 7.53956C23.9996 7.75799 23.8215 7.93459 23.6012 7.93459Z"
        fill="#F9AD2F"
      />
      <path
        d="M20.8828 5.55944C20.6812 5.55944 20.5125 5.41073 20.4844 5.21554C20.4562 5.02034 20.5828 4.8298 20.7797 4.77868L23.0953 4.16058C23.2312 4.1234 23.3812 4.16058 23.4797 4.26282C23.5828 4.36506 23.6203 4.50913 23.5828 4.64391C23.5453 4.77868 23.4375 4.89022 23.3016 4.92275L20.9859 5.54085C20.9531 5.5548 20.9156 5.55944 20.8828 5.55944Z"
        fill="#F9AD2F"
      />
      <path
        d="M19.9548 3.34759C19.772 3.34759 19.6173 3.22676 19.5704 3.0548C19.5236 2.88285 19.5986 2.69695 19.7579 2.60865L21.8345 1.41427C21.9564 1.33991 22.1111 1.33526 22.2376 1.40497C22.3642 1.47468 22.4439 1.60946 22.4392 1.75353C22.4392 1.8976 22.3595 2.02773 22.2329 2.09744L20.1564 3.29182C20.0954 3.329 20.0251 3.34759 19.9548 3.34759Z"
        fill="#F9AD2F"
      />
      <path
        d="M23.1979 10.9278C23.1604 10.9278 23.1276 10.9232 23.0948 10.9139L20.7792 10.2958C20.6432 10.2586 20.5354 10.1517 20.4979 10.017C20.4604 9.88218 20.5026 9.73346 20.6011 9.63586C20.7042 9.53362 20.8495 9.49644 20.9854 9.53362L23.3011 10.1517C23.4932 10.2028 23.6198 10.3887 23.5964 10.5886C23.5682 10.7791 23.3995 10.9278 23.1979 10.9278Z"
        fill="#F9AD2F"
      />
      <path
        d="M22.0258 13.7158C21.9555 13.7158 21.8852 13.6972 21.8243 13.66L19.7477 12.4656C19.5696 12.3495 19.5133 12.1171 19.6211 11.9358C19.729 11.7546 19.9633 11.6849 20.1508 11.7825L22.2274 12.9769C22.3821 13.0652 22.4571 13.2511 22.4102 13.423C22.368 13.595 22.2086 13.7158 22.0258 13.7158Z"
        fill="#F9AD2F"
      />
      <path
        d="M2.79844 7.93459H0.398438C0.178125 7.93459 0 7.75799 0 7.53956C0 7.32113 0.178125 7.14453 0.398438 7.14453H2.79844C3.01875 7.14453 3.19687 7.32113 3.19687 7.53956C3.19687 7.75799 3.01875 7.93459 2.79844 7.93459Z"
        fill="#F9AD2F"
      />
      <path
        d="M3.1168 5.55891C3.0793 5.55891 3.04649 5.55426 3.01367 5.54497L0.693362 4.92686C0.477737 4.87109 0.355862 4.65267 0.412112 4.43889C0.468362 4.22511 0.688675 4.10427 0.9043 4.16004L3.21992 4.77815C3.41211 4.82927 3.53868 5.01516 3.51524 5.215C3.48711 5.41019 3.31836 5.55891 3.1168 5.55891Z"
        fill="#F9AD2F"
      />
      <path
        d="M4.04609 3.34731C3.97578 3.34731 3.90547 3.32872 3.84453 3.29154L1.77266 2.09715C1.64609 2.02744 1.56641 1.89732 1.56641 1.75325C1.56641 1.60918 1.64609 1.4744 1.76797 1.40469C1.89453 1.33498 2.04922 1.33498 2.17109 1.41399L4.24766 2.60837C4.40234 2.69667 4.48203 2.88257 4.43516 3.05452C4.38828 3.22647 4.22891 3.34731 4.04609 3.34731Z"
        fill="#F9AD2F"
      />
      <path
        d="M0.800776 10.9277C0.599214 10.9277 0.430464 10.779 0.402339 10.5838C0.374214 10.3886 0.500776 10.1981 0.697651 10.147L3.01328 9.52887C3.2289 9.47311 3.44453 9.59859 3.50546 9.80772C3.5664 10.0169 3.43515 10.2353 3.22421 10.2957L0.908589 10.9138C0.871089 10.9231 0.833589 10.9277 0.800776 10.9277Z"
        fill="#F9AD2F"
      />
      <path
        d="M1.97438 13.7152C1.79157 13.7152 1.63688 13.5944 1.59001 13.4224C1.54313 13.2504 1.61813 13.0692 1.77282 12.9763L3.8447 11.7772C4.03688 11.6657 4.28063 11.7307 4.39313 11.9213C4.50563 12.1118 4.44001 12.3535 4.24782 12.465L2.17126 13.6594C2.11501 13.6966 2.0447 13.7152 1.97438 13.7152Z"
        fill="#F9AD2F"
      />
    </g>
    <defs>
      <clipPath id="clip0_5766_8087">
        <rect width="24" height="23" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "LightIcon",
};
</script>
