<template>
  <div style="width: 100%">
    <div class="chart mb-6">
      <PieChart
        :series-color="['#6B6B6B', '#8E8E8E', '#A7A7A7', '#E2E2E2']"
        :series-title="'Doanh số'"
        :inner-size="'50%'"
        :series-data="seriesData"
        :categories-name="categoriesName"
        :chart-height="500"
      />
    </div>
    <div
      class="note-box mt-6"
      :style="{ visibility: !official_types.length ? 'hidden' : '' }"
    >
      <p class="charting-percent">
        <span>Có {{ official_types[1] | formatNumberHuman }} shop Mall</span>:
        chiếm
        {{
          official_types[1]
            | compareNumberByPercent(official_types[1] + official_types[0])
        }}%
      </p>
      <p class="unknown-percent">
        <span>Có {{ official_types[0] | formatNumberHuman }} shop thường</span>:
        chiếm
        {{
          official_types[0]
            | compareNumberByPercent(official_types[1] + official_types[0])
        }}%
      </p>
    </div>
    <PlatformBtns
      @onSelect="(platform) => (selectedPlatform = platform.platform_id)"
      :selected="selectedPlatform"
      :loading="loading"
    />
  </div>
</template>

<script>
import PieChart from "@/components/chart/PieChart";
import {
  compareNumberByPercent,
  formatCurrency,
  formatNumberHuman,
} from "@/helper/FormatHelper";
import { ALL_PLATFORM_BASE_OBJECT } from "@/constant/general/GeneralConstant";
import axios from "axios";
import { URL_MARKET_BACKEND_API } from "@/config/ConfigEndpoint";

import PlatformBtns from "./PlatformBtns";

const NUMBER_CATEGORIES_DISPLAY = 12;

const PLATFORMS = [
  ALL_PLATFORM_BASE_OBJECT.shopee,
  ALL_PLATFORM_BASE_OBJECT.tiki,
  ALL_PLATFORM_BASE_OBJECT.lazada,
  ALL_PLATFORM_BASE_OBJECT.sendo,
];

const DEFAULT_COMPOSITE_COMPARE_LST = [
  [
    {
      first_field: "rating_count",
      second_field: "order_count",
      first_rate: 1,
      second_rate: 0.05,
      compare_condition: ">",
      is_default: true,
    },
  ],
];

const CHART_DATA_TYPE = "brands";
const DURATION_DAY = 30;

export default {
  name: "TopShopChart",
  components: {
    PieChart,
    PlatformBtns,
  },
  data() {
    return {
      loading: false,
      selectedPlatform: ALL_PLATFORM_BASE_OBJECT.shopee.platform_id,
      chartData: {
        [ALL_PLATFORM_BASE_OBJECT.shopee.platform_id]: {},
        [ALL_PLATFORM_BASE_OBJECT.tiki.platform_id]: {},
        [ALL_PLATFORM_BASE_OBJECT.lazada.platform_id]: {},
        [ALL_PLATFORM_BASE_OBJECT.sendo.platform_id]: {},
      },
      ALL_PLATFORM_BASE_OBJECT,
      DURATION_DAY,
      PLATFORMS,
    };
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    official_types() {
      return this.chartData[this.selectedPlatform].official_types || [];
    },
    seriesData() {
      return (
        this.chartData[this.selectedPlatform][`order_count_${DURATION_DAY}d`] ||
        []
      ).map((e) => e.value);
    },
    categoriesName() {
      return (
        this.chartData[this.selectedPlatform][`order_count_${DURATION_DAY}d`] ||
        []
      ).map((e) => e.key);
    },
  },
  methods: {
    async fetchData() {
      const allPlatformChartData = await Promise.all(
        PLATFORMS.map(async (platform) => {
          return await this.fetchDataShop(platform.platform_id);
        })
      );

      this.chartData = PLATFORMS.reduce((result, currentValue, index) => {
        result[currentValue.platform_id] = allPlatformChartData[index];
        return result;
      }, {});
    },
    async fetchOfficialTypeData(platform_id) {
      const url = `${URL_MARKET_BACKEND_API}/market/widget/beecostvn/search_product`;
      const body = {
        include_query: {
          platforms: [platform_id], // all
          categories_level: 1, // hardcode
          is_remove_bad_product: true, // Lọc sản phẩm ảo/bất thường - hardcode
          official_types: [1, 0], // hardcode
          composite_compare_lst: DEFAULT_COMPOSITE_COMPARE_LST,
          [`order_count_${DURATION_DAY}d_range`]: {
            from: 1,
            is_default_from: true,
          },
        },
        durationDay: DURATION_DAY,
        statistics: ["official_type"],
      };
      return await axios.post(url, body, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    },
    async fetchDataShop(platform_id) {
      try {
        const url = `${URL_MARKET_BACKEND_API}/market/widget/beecostvn/search_shop`;
        const body = {
          include_query: {
            platforms: [platform_id], // all
            categories_level: 1, // hardcode
            is_remove_bad_product: true, // Lọc sản phẩm ảo/bất thường - hardcode
            official_types: [1, 0], // hardcode
            composite_compare_lst: DEFAULT_COMPOSITE_COMPARE_LST,
            [`order_count_${DURATION_DAY}d_range`]: {
              from: 1,
              is_default_from: true,
            },
          },
          limit: 10,
          page: 0,
          sort_by: `order_revenue_${DURATION_DAY}d__desc`,
          DURATION_DAY,
        };
        const response = await axios.post(url, body, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        const searchProductResponse = await this.fetchOfficialTypeData(
          platform_id
        );

        const { aggregations } = searchProductResponse.data.data || {};
        if (!aggregations) return {};

        const official_types = aggregations
          .find((i) => i.aggregation.toLowerCase() === "official_type")
          .entities.map((e) => e.value);
        const { shops } = response.data.data;

        return {
          [`order_count_${DURATION_DAY}d`]: shops.map((item) => ({
            key: item.shop_name,
            value: item[`order_count_${DURATION_DAY}d`],
          })),
          [`order_revenue_${DURATION_DAY}d`]: shops.map((item) => ({
            key: item.shop_name,
            value: item[`order_revenue_${DURATION_DAY}d`],
          })),
          official_types,
        };
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
  },
  filters: {
    formatCurrency,
    formatNumberHuman,
    compareNumberByPercent,
  },
};
</script>

<style lang="scss" scoped>
.chart-header {
  text-align: center;
  .chart-name {
    font-style: normal;
    font-size: 18px;
    line-height: 21px;
    color: #3b3b3b;
  }
}

.note-box {
  .charting-percent {
    color: #096dd9;
  }
}

@media (min-width: 575px) {
}
</style>
