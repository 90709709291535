<template>
  <span>{{ displayNumber | parseBillion }}</span>
</template>

<script>
import { formatNumberHuman } from "@/helper/FormatHelper";

const parseBillion = (value) =>
  value ? formatNumberHuman(value / 1000000000) + " Tỷ" : "";

export default {
  name: "AnimatedNumber",
  props: { number: { default: 0 } },
  data() {
    return {
      displayNumber: 0,
      interval: false,
    };
  },
  mounted() {
    this.displayNumber = this.number ? this.number : 0;
  },
  filters: {
    parseBillion,
  },
  watch: {
    number: function (newValue) {
      clearInterval(this.interval);

      if (this.number == this.displayNumber) {
        return;
      }

      this.interval = setInterval(() => {
        if (this.displayNumber != this.number) {
          let change = (this.number - this.displayNumber) / 10;

          change = change >= 0 ? Math.ceil(change) : Math.floor(change);

          this.displayNumber = this.displayNumber + change;
        }
      }, 5);
    },
  },
};
</script>

<style>
</style>
