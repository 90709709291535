<template>
  <svg
    width="67"
    height="57"
    viewBox="0 0 67 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M46.0606 31.8495C44.4241 42.9572 34.7949 51.4787 23.16 51.4787C10.3776 51.4688 0 41.1836 0 28.4707C0 15.7776 10.3776 5.48242 23.16 5.48242V28.4707L46.0606 31.8495Z"
      fill="#FF7227"
      fill-opacity="0.48"
    />
    <path
      d="M50.9073 23.8643C50.9073 25.0256 50.8179 26.1569 50.6591 27.2681L27.877 23.8643V0.638672C36.424 0.638672 43.889 5.33387 47.8696 12.3116C49.8054 15.7153 50.9073 19.6697 50.9073 23.8643Z"
      fill="#FF7227"
    />
    <path
      d="M40.1618 32.4644C35.539 32.4696 31.5544 29.1267 30.6456 24.4779C30.5113 23.5728 31.1181 22.7288 32.0007 22.5911C32.0865 22.5776 32.1744 22.5714 32.2612 22.5724C33.0559 22.561 33.7416 23.1441 33.8769 23.9477C34.609 27.5308 38.0341 29.8257 41.527 29.0759C44.0383 28.5364 46.0013 26.5242 46.5274 23.9477C46.6627 23.1441 47.3484 22.561 48.1431 22.5724C49.0357 22.5621 49.7668 23.2953 49.7769 24.2107C49.7779 24.2998 49.7718 24.3899 49.7587 24.4779C48.8439 29.1577 44.8148 32.5099 40.1618 32.4644Z"
      fill="#050038"
    />
    <path
      d="M54.5413 54.3022H25.7827C23.1058 54.3053 20.9327 52.0829 20.9297 49.3376C20.9297 49.2527 20.9317 49.1677 20.9357 49.0828L22.2444 20.5171C22.3565 17.8567 24.4942 15.7596 27.0914 15.7617L53.2326 15.7617C55.8298 15.7596 57.9675 17.8567 58.0796 20.5171L59.3883 49.0828C59.5226 51.8251 57.4636 54.1582 54.7897 54.296C54.7069 54.3001 54.6241 54.3022 54.5413 54.3022ZM27.0914 19.0922C26.1987 19.0922 25.4757 19.8337 25.4757 20.7491L24.1671 49.2485C24.1226 50.1629 24.8083 50.9407 25.6999 50.9862C25.7272 50.9873 25.7554 50.9883 25.7827 50.9883H54.5413C55.434 50.9893 56.158 50.2489 56.159 49.3334C56.159 49.3055 56.158 49.2765 56.157 49.2485L54.8483 20.6828C54.8483 19.7674 54.1253 19.0259 53.2326 19.0259L27.0914 19.0922Z"
      fill="#050038"
    />
    <path
      d="M49.8566 17.4351H46.6253V14.5354C46.6253 10.8746 43.7322 7.90763 40.1627 7.90763C36.5931 7.90763 33.7001 10.8746 33.7001 14.5354V17.4351H30.4688V14.5354C30.4688 9.04471 34.8088 4.59375 40.1627 4.59375C45.5165 4.59375 49.8566 9.04471 49.8566 14.5354V17.4351Z"
      fill="#050038"
    />
  </svg>
</template>

<script>
export default {
  name: "ShoppingIcon",
};
</script>
