<template>
  <div class="slides">
    <div class="slide-item slide-1">
      <div class="left-content">
        <div class="mb-8">
          <DeliveryIcon />
        </div>
        <div>
          <div class="text-2xl md:text-4xl mb-3 md:mb-6 font-semibold">
            350.000.000+
          </div>
          <div class="flex text-lg mb-6 md:mb-9">
            <LightIcon />
            <span class="ml-1">Dữ liệu sản phẩm cập nhật mỗi ngày</span>
          </div>
        </div>
        <div>
          <div class="text-2xl md:text-4xl mb-3 md:mb-6 font-semibold">
            2.000.000.000+
          </div>
          <div class="flex text-lg mb-6 md:mb-9">
            <LightIcon />
            <span class="ml-1">Điểm dữ liệu lịch sử giá được cập nhật</span>
          </div>
        </div>
        <div class="flex items-center">
          <div class="open-signup-form-btn mr-6" @click="$emit('openSignUp')">
            Đăng ký nhận tư vấn
          </div>
          <a href="/analytics" class="start-analysis-btn">
            Bắt đầu phân tích
            <svg
              width="8"
              height="10"
              viewBox="0 0 8 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.800781 0.599609L7.40078 5.03261L0.800781 9.39961V0.599609Z"
                fill="#2E7CF6"
              />
            </svg>
          </a>
        </div>
      </div>
      <div class="right-content">
        <div class="frame" style="background: #dbd8ff">
          <Slide1BackgroundShape class="background-shape" />
          <div class="box flex items-center">
            <img
              width="100%"
              src="https://lh3.googleusercontent.com/pw/AM-JKLWkNQaJdxXvS7-fLNR0kbcG-OCz_aGi1GXvzTzj7ys3PhInSRQ1pA8gAGNWKdxdPQ6BT79_XHJQuGBMB4Y-SfbbjFF8fr3qnTga72Anzb6CXoMMvGIwlhuJOrIh7go1riFzMqiUDW_0i_J-7GiAUsVC=w1842-h1438-no"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="slide-item slide-2">
      <div class="left-content">
        <div class="mb-8">
          <ShoppingIcon />
        </div>
        <div>
          <div class="text-2xl md:text-4xl mb-3 md:mb-6 font-semibold">
            3.474.116+
          </div>
          <div class="flex text-lg mb-6 md:mb-9">
            <LightIcon />
            <span class="ml-1">Shop Mall và Shop thường</span>
          </div>
        </div>
        <div class="flex items-center">
          <div class="open-signup-form-btn mr-6" @click="$emit('openSignUp')">
            Đăng ký nhận tư vấn
          </div>
          <a href="/analytics" class="start-analysis-btn">
            Bắt đầu phân tích
            <svg
              width="8"
              height="10"
              viewBox="0 0 8 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.800781 0.599609L7.40078 5.03261L0.800781 9.39961V0.599609Z"
                fill="#2E7CF6"
              />
            </svg>
          </a>
        </div>
      </div>
      <div class="right-content">
        <div class="frame" style="background: #ffcbb0">
          <Slide2BackgroundShape class="background-shape" />
          <div class="box" style="flex: 1">
            <div class="chart-title text-center mb-3">
              Top 10 nhà bán hàng trên các sàn
            </div>
            <div
              class="text-center mb-3"
              style="font-family: 'Montserrat'; color: #7a8bab; font-size: 14px"
            >
              Doanh số
            </div>
            <div class="mb-8 flex justify-center">
              <TopShopChart />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="slide-item slide-3">
      <div class="left-content">
        <div class="mb-8">
          <RewardIcon />
        </div>
        <div>
          <div class="text-2xl md:text-4xl mb-3 md:mb-6 font-semibold">
            86.343+
          </div>
          <div class="flex text-lg mb-6 md:mb-9">
            <LightIcon />
            <span class="ml-1">Thương hiệu có mặt trên thị trường</span>
          </div>
        </div>
        <div class="flex items-center">
          <div class="open-signup-form-btn mr-6" @click="$emit('openSignUp')">
            Đăng ký nhận tư vấn
          </div>
          <a href="/analytics" class="start-analysis-btn">
            Bắt đầu phân tích
            <svg
              width="8"
              height="10"
              viewBox="0 0 8 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.800781 0.599609L7.40078 5.03261L0.800781 9.39961V0.599609Z"
                fill="#2E7CF6"
              />
            </svg>
          </a>
        </div>
      </div>
      <div class="right-content">
        <div class="frame" style="background: #9fded3">
          <Slide3BackgroundShape class="background-shape" />
          <div class="box" style="flex: 1">
            <div class="chart-title text-center mb-3">
              Top 10 thương hiệu trên các sàn
            </div>
            <div
              class="text-center mb-3"
              style="font-family: 'Montserrat'; color: #7a8bab; font-size: 14px"
            >
              Doanh số
            </div>
            <div class="mb-8 flex justify-center">
              <BrandChart />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DeliveryIcon from "@/views/homePage/components/icons/DeliveryIcon";
import LightIcon from "@/views/homePage/components/icons/LightIcon";
import ShoppingIcon from "@/views/homePage/components/icons/ShoppingIcon";
import RewardIcon from "@/views/homePage/components/icons/RewardIcon";
import Slide1BackgroundShape from "@/views/homePage/components/shapes/Slide1BackgroundShape";
import Slide2BackgroundShape from "@/views/homePage/components/shapes/Slide2BackgroundShape";
import Slide3BackgroundShape from "@/views/homePage/components/shapes/Slide3BackgroundShape";
import { mixinStoreGetterGlobal } from "@/mixin/store/MixinGlobal";

import TopShopChart from "./TopShopChart";
import BrandChart from "./BrandChart";

export default {
  name: "Slides",
  components: {
    DeliveryIcon,
    LightIcon,
    ShoppingIcon,
    RewardIcon,
    Slide1BackgroundShape,
    Slide2BackgroundShape,
    Slide3BackgroundShape,
    TopShopChart,
    BrandChart,
  },
  mixins: [mixinStoreGetterGlobal],
  mounted() {
    // if (this.isDesktop) {
    //   const items = document.querySelectorAll(".slide-item");

    //   for (let i = 0; i < items.length; i++) {
    //     const scene = this.$scrollmagic
    //       .scene({
    //         triggerElement: items[i],
    //         triggerHook: 0,
    //         duration: 0,
    //         offset: -100,
    //       })
    //       .setPin(items[i], { pushFollowers: true });

    //     this.$scrollmagic.addScene(scene);
    //   }
    // }
  },
  data() {
    return {
      activeSlide: 0,
      wheelScrollY: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.slides {
  .slide-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .left-content {
      font-family: "Montserrat";

      padding: 0 16px;

      color: #050038;

      display: flex;
      flex-direction: column;
      justify-content: center;

      .open-signup-form-btn,
      .start-analysis-btn {
        display: none;
      }
    }
    .right-content {
      width: 100%;
      margin-bottom: 32px;
      .frame {
        position: relative;
        z-index: 0;

        overflow: hidden;

        padding: 36px;

        height: fit-content;
        .box {
          background: rgba(255, 255, 255, 0.9);
          backdrop-filter: blur(20px);

          border-radius: 8px;
          display: flex;
          flex-direction: column;
          justify-content: center;

          padding: 12px;
          width: 100%;
          height: fit-content;
          .chart-title {
            font-family: "Montserrat";
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;

            color: #050038;
          }
        }

        .background-shape {
          position: absolute;
          left: 0;
          bottom: 0;

          z-index: -1;
        }

        .statistic-info {
          font-family: "Roboto";
          font-weight: 400;
          font-size: 8px;
          line-height: 9px;

          margin-bottom: 4px;

          color: #a4a4a5;
          &:first-child {
            color: #2368d4;
          }
        }
      }
    }
    &.slide-2 {
      .chart-image {
        height: 160px;
      }
      .box img {
        width: 250px;
        margin: auto;
      }
    }

    &.slide-3 {
      .chart-image {
        height: 220px;
      }
      .box img {
        width: 250px;
        margin: auto;
      }
    }
  }
}

@media (min-width: 575px) {
  .slides {
    position: relative;
    .slide-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      min-height: calc(100vh - 100px);

      background-color: #fff;

      .left-content {
        font-family: "Montserrat";

        padding: 0;
        color: #050038;

        .open-signup-form-btn,
        .start-analysis-btn {
          display: flex;
        }
        .start-analysis-btn {
          align-items: center;

          svg {
            margin-left: 8px;
          }
        }
      }
      .right-content {
        width: 690px;
        margin-bottom: 0;
        .frame {
          position: relative;
          z-index: 0;

          overflow: hidden;

          padding: 60px;

          height: 100%;
          .box {
            background: rgba(255, 255, 255, 0.9);
            backdrop-filter: blur(20px);

            border-radius: 8px;

            padding: 22px 37px;
            width: 100%;
            height: 100%;

            .chart-title {
              font-size: 18px;
              line-height: 22px;

              color: #050038;
            }
          }

          .statistic-info {
            font-size: 12px;
            line-height: 14px;

            margin-bottom: 8px;
          }
        }
      }
      &.slide-2 {
        .chart-image {
          // height: 235px;
        }
        .box img {
          // width: 330px;
        }
      }

      &.slide-3 {
        .chart-image {
          // height: 350px;
        }
        .box img {
          // width: 430px;
        }
      }
    }
  }
}
</style>
