<template>
  <svg
    width="688"
    height="464"
    viewBox="0 0 688 464"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M479.53 190.664L438.294 373.993C437.21 378.786 431.071 380.231 427.985 376.422L399.619 341.72L396.369 337.747L395.515 336.697L389.113 328.883C387.044 326.355 383.302 325.994 380.774 328.062L343.182 359.219L342.361 359.908L217.209 463.72H40.0195L309.399 240.633C311.894 238.564 312.255 234.887 310.187 232.359L287.402 204.486L286.516 203.435L278.702 193.881C275.55 190.04 278.275 184.262 283.232 184.262L353.787 183.999L371.154 183.934L397.879 183.835H402.409L413.506 183.802L420.696 183.769L440.1 183.704L456.351 183.638L473.686 183.572C477.527 183.474 480.351 186.987 479.53 190.664Z"
      fill="#ED926B"
      fill-opacity="0.2"
    />
    <path
      d="M224.795 43.2209L205.982 126.809C205.424 129.337 202.207 130.092 200.565 128.089L183.296 106.979C182.213 105.666 180.243 105.469 178.93 106.552L0 257.05V179.306L145.967 66.1042C147.28 65.0208 147.477 63.0837 146.394 61.7705L132.539 44.8296C130.898 42.8269 132.309 39.8065 134.903 39.7736L221.741 39.4453C223.744 39.4781 225.221 41.3167 224.795 43.2209Z"
      fill="#FFECDE"
    />
    <path
      d="M564.923 335.88C563.249 333.91 562.888 331.218 563.971 328.854C565.054 326.49 567.353 325.046 569.946 325.013L653.633 324.717L679.701 324.619H679.733C681.736 324.619 683.64 325.538 684.888 327.114C686.136 328.69 686.595 330.758 686.168 332.728L662.399 434.735C661.808 437.197 660.002 439.068 657.507 439.659C655.044 440.25 652.582 439.462 650.941 437.492L632.522 415.758C629.83 412.574 625.135 412.114 621.885 414.74L560.622 463.724H562.329L622.542 415.528C625.365 413.296 629.371 413.657 631.702 416.415L650.12 438.182C651.991 440.414 654.946 441.366 657.769 440.677C660.593 439.988 662.76 437.821 663.416 434.964L687.186 332.958C687.711 330.66 687.186 328.296 685.709 326.458C684.264 324.619 682.064 323.568 679.733 323.568H679.701L653.863 323.667L569.946 323.962C566.926 323.962 564.266 325.67 563.019 328.427C561.771 331.152 562.198 334.271 564.135 336.569L576.939 351.705C578.088 353.084 578.646 354.824 578.482 356.596C578.318 358.369 577.432 359.978 576.02 361.127L447.814 463.724H449.522L576.677 361.948C578.318 360.635 579.303 358.796 579.5 356.695C579.697 354.627 579.073 352.591 577.727 351.015L564.923 335.88Z"
      fill="#FFB7A0"
    />
    <path
      d="M284.778 9.30632C284.778 9.30632 289.473 -1.19964 301.391 0.113602C313.275 1.42685 311.864 11.9656 317.543 10.9807C323.223 9.99576 331.136 10.9807 332.843 17.5469C334.55 24.1131 342.2 20.5017 342.462 26.083C342.758 31.6643 333.696 35.6369 323.19 30.6794C312.717 25.7547 307.596 34.5206 292.887 32.9119C278.146 31.336 276.34 29.3661 267.607 30.3511C258.874 31.336 259.104 24.4086 263.077 19.8123C267.016 15.2159 283.071 15.6756 284.778 9.30632Z"
      fill="#F7F0F0"
    />
    <path
      d="M638.598 186.003C638.598 186.003 630.62 169.817 610.495 171.853C590.336 173.888 592.733 190.107 583.146 188.564C573.56 187.054 560.132 188.564 557.243 198.709C554.354 208.854 541.418 203.272 540.926 211.874C540.433 220.476 555.798 226.549 573.56 218.966C591.321 211.382 599.956 224.875 624.908 222.413C649.859 219.983 652.945 216.93 667.719 218.44C682.493 219.95 682.132 209.28 675.435 202.189C668.672 195.13 641.487 195.787 638.598 186.003Z"
      fill="#F7F0F0"
    />
    <path
      d="M554.747 141.057C554.747 141.057 550.84 133.21 540.958 134.195C531.076 135.18 532.258 143.059 527.53 142.304C522.835 141.582 516.236 142.304 514.825 147.229C513.413 152.154 507.044 149.429 506.814 153.631C506.584 157.8 514.102 160.755 522.835 157.078C531.536 153.401 535.804 159.934 548.05 158.753C560.296 157.571 561.806 156.093 569.062 156.816C576.317 157.538 576.153 152.383 572.837 148.936C569.521 145.456 556.159 145.784 554.747 141.057Z"
      fill="#F7F0F0"
    />
    <path
      d="M621.035 83.1434C621.035 83.1434 616.373 73.7865 604.586 74.9685C592.8 76.1504 594.179 85.5073 588.565 84.6536C582.951 83.7672 575.071 84.6536 573.397 90.5304C571.722 96.4072 564.138 93.1569 563.843 98.1472C563.548 103.138 572.543 106.65 582.951 102.251C593.358 97.8517 598.414 105.666 612.991 104.254C627.601 102.842 629.407 101.069 638.041 101.956C646.676 102.842 646.479 96.6698 642.539 92.5659C638.632 88.4292 622.709 88.8232 621.035 83.1434Z"
      fill="#F7F0F0"
    />
    <path
      d="M384.814 463.724H486.558L605.998 364.706C607.212 363.721 608.985 363.885 609.97 365.1L633.445 393.794C634.922 395.6 637.844 394.91 638.369 392.645L653.636 324.717L653.865 323.667L662.467 285.386C662.861 283.613 661.515 281.938 659.709 281.971L548.215 282.398C545.851 282.398 544.538 285.156 546.048 286.994L565.287 310.534C566.272 311.749 566.108 313.489 564.893 314.474L384.814 463.724Z"
      fill="#F8BA98"
    />
  </svg>
</template>

<script>
export default {
  name: "Slide2BackgroundShape",
};
</script>
