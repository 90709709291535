<template>
  <svg width="1244" height="924" viewBox="0 0 1244 924" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="689" y="193.576" width="71.1009" height="71.1009" transform="rotate(-60 689 193.576)" fill="#EFE8D4"/>
    <circle cx="198.129" cy="255.129" r="198.129" fill="#EFE8D4"/>
    <circle cx="23.2602" cy="625.416" r="6.94378" fill="#EFE8D4"/>
    <circle cx="23.2602" cy="657.821" r="6.94378" fill="#EFE8D4"/>
    <circle cx="23.2602" cy="690.225" r="6.94378" fill="#EFE8D4"/>
    <circle cx="23.2602" cy="722.629" r="6.94378" fill="#EFE8D4"/>
    <circle cx="23.2602" cy="755.034" r="6.94378" fill="#EFE8D4"/>
    <circle cx="23.2602" cy="787.438" r="6.94378" fill="#EFE8D4"/>
    <circle cx="23.2602" cy="819.842" r="6.94378" fill="#EFE8D4"/>
    <circle cx="23.2602" cy="852.247" r="6.94378" fill="#EFE8D4"/>
    <circle cx="23.2602" cy="884.653" r="6.94378" fill="#EFE8D4"/>
    <circle cx="23.2602" cy="917.055" r="6.94378" fill="#EFE8D4"/>
    <circle cx="55.6645" cy="625.416" r="6.94378" fill="#EFE8D4"/>
    <circle cx="55.6645" cy="657.821" r="6.94378" fill="#EFE8D4"/>
    <circle cx="55.6645" cy="690.225" r="6.94378" fill="#EFE8D4"/>
    <circle cx="55.6645" cy="722.629" r="6.94378" fill="#EFE8D4"/>
    <circle cx="55.6645" cy="755.034" r="6.94378" fill="#EFE8D4"/>
    <circle cx="55.6645" cy="787.438" r="6.94378" fill="#EFE8D4"/>
    <circle cx="55.6645" cy="819.842" r="6.94378" fill="#EFE8D4"/>
    <circle cx="55.6645" cy="852.247" r="6.94378" fill="#EFE8D4"/>
    <circle cx="55.6645" cy="884.653" r="6.94378" fill="#EFE8D4"/>
    <circle cx="55.6645" cy="917.055" r="6.94378" fill="#EFE8D4"/>
    <circle cx="88.0688" cy="625.416" r="6.94378" fill="#EFE8D4"/>
    <circle cx="88.0688" cy="657.821" r="6.94378" fill="#EFE8D4"/>
    <circle cx="88.0688" cy="690.225" r="6.94378" fill="#EFE8D4"/>
    <circle cx="88.0688" cy="722.629" r="6.94378" fill="#EFE8D4"/>
    <circle cx="88.0688" cy="755.034" r="6.94378" fill="#EFE8D4"/>
    <circle cx="88.0688" cy="787.438" r="6.94378" fill="#EFE8D4"/>
    <circle cx="88.0688" cy="819.842" r="6.94378" fill="#EFE8D4"/>
    <circle cx="88.0688" cy="852.247" r="6.94378" fill="#EFE8D4"/>
    <circle cx="88.0688" cy="884.653" r="6.94378" fill="#EFE8D4"/>
    <circle cx="88.0688" cy="917.055" r="6.94378" fill="#EFE8D4"/>
    <circle cx="120.473" cy="625.416" r="6.94378" fill="#EFE8D4"/>
    <circle cx="120.473" cy="657.821" r="6.94378" fill="#EFE8D4"/>
    <circle cx="120.473" cy="690.225" r="6.94378" fill="#EFE8D4"/>
    <circle cx="120.473" cy="722.629" r="6.94378" fill="#EFE8D4"/>
    <circle cx="120.473" cy="755.034" r="6.94378" fill="#EFE8D4"/>
    <circle cx="120.473" cy="787.438" r="6.94378" fill="#EFE8D4"/>
    <circle cx="120.473" cy="819.842" r="6.94378" fill="#EFE8D4"/>
    <circle cx="120.473" cy="852.247" r="6.94378" fill="#EFE8D4"/>
    <circle cx="120.473" cy="884.653" r="6.94378" fill="#EFE8D4"/>
    <circle cx="120.473" cy="917.055" r="6.94378" fill="#EFE8D4"/>
    <circle cx="152.877" cy="625.416" r="6.94378" fill="#EFE8D4"/>
    <circle cx="152.877" cy="657.821" r="6.94378" fill="#EFE8D4"/>
    <circle cx="152.877" cy="690.225" r="6.94378" fill="#EFE8D4"/>
    <circle cx="152.877" cy="722.629" r="6.94378" fill="#EFE8D4"/>
    <circle cx="152.877" cy="755.034" r="6.94378" fill="#EFE8D4"/>
    <circle cx="152.877" cy="787.438" r="6.94378" fill="#EFE8D4"/>
    <circle cx="152.877" cy="819.842" r="6.94378" fill="#EFE8D4"/>
    <circle cx="152.877" cy="852.247" r="6.94378" fill="#EFE8D4"/>
    <circle cx="152.877" cy="884.653" r="6.94378" fill="#EFE8D4"/>
    <circle cx="152.877" cy="917.055" r="6.94378" fill="#EFE8D4"/>
    <circle cx="185.282" cy="625.416" r="6.94378" fill="#EFE8D4"/>
    <circle cx="185.282" cy="657.821" r="6.94378" fill="#EFE8D4"/>
    <circle cx="185.282" cy="690.225" r="6.94378" fill="#EFE8D4"/>
    <circle cx="185.282" cy="722.629" r="6.94378" fill="#EFE8D4"/>
    <circle cx="185.282" cy="755.034" r="6.94378" fill="#EFE8D4"/>
    <circle cx="185.282" cy="787.438" r="6.94378" fill="#EFE8D4"/>
    <circle cx="185.282" cy="819.842" r="6.94378" fill="#EFE8D4"/>
    <circle cx="185.282" cy="852.247" r="6.94378" fill="#EFE8D4"/>
    <circle cx="185.282" cy="884.653" r="6.94378" fill="#EFE8D4"/>
    <circle cx="185.282" cy="917.055" r="6.94378" fill="#EFE8D4"/>
    <circle cx="217.686" cy="625.416" r="6.94378" fill="#EFE8D4"/>
    <circle cx="217.686" cy="657.821" r="6.94378" fill="#EFE8D4"/>
    <circle cx="217.686" cy="690.225" r="6.94378" fill="#EFE8D4"/>
    <circle cx="217.686" cy="722.629" r="6.94378" fill="#EFE8D4"/>
    <circle cx="217.686" cy="755.034" r="6.94378" fill="#EFE8D4"/>
    <circle cx="217.686" cy="787.438" r="6.94378" fill="#EFE8D4"/>
    <circle cx="217.686" cy="819.842" r="6.94378" fill="#EFE8D4"/>
    <circle cx="217.686" cy="852.247" r="6.94378" fill="#EFE8D4"/>
    <circle cx="217.686" cy="884.653" r="6.94378" fill="#EFE8D4"/>
    <circle cx="217.686" cy="917.055" r="6.94378" fill="#EFE8D4"/>
    <circle cx="250.09" cy="625.416" r="6.94378" fill="#EFE8D4"/>
    <circle cx="250.09" cy="657.821" r="6.94378" fill="#EFE8D4"/>
    <circle cx="250.09" cy="690.225" r="6.94378" fill="#EFE8D4"/>
    <circle cx="250.09" cy="722.629" r="6.94378" fill="#EFE8D4"/>
    <circle cx="250.09" cy="755.034" r="6.94378" fill="#EFE8D4"/>
    <circle cx="250.09" cy="787.438" r="6.94378" fill="#EFE8D4"/>
    <circle cx="250.09" cy="819.842" r="6.94378" fill="#EFE8D4"/>
    <circle cx="250.09" cy="852.247" r="6.94378" fill="#EFE8D4"/>
    <circle cx="250.09" cy="884.653" r="6.94378" fill="#EFE8D4"/>
    <circle cx="250.09" cy="917.055" r="6.94378" fill="#EFE8D4"/>
    <circle cx="282.497" cy="625.416" r="6.94378" fill="#EFE8D4"/>
    <circle cx="282.497" cy="657.821" r="6.94378" fill="#EFE8D4"/>
    <circle cx="282.497" cy="690.225" r="6.94378" fill="#EFE8D4"/>
    <circle cx="282.497" cy="722.629" r="6.94378" fill="#EFE8D4"/>
    <circle cx="282.497" cy="755.034" r="6.94378" fill="#EFE8D4"/>
    <circle cx="282.497" cy="787.438" r="6.94378" fill="#EFE8D4"/>
    <circle cx="282.497" cy="819.842" r="6.94378" fill="#EFE8D4"/>
    <circle cx="282.497" cy="852.247" r="6.94378" fill="#EFE8D4"/>
    <circle cx="282.497" cy="884.653" r="6.94378" fill="#EFE8D4"/>
    <circle cx="282.497" cy="917.055" r="6.94378" fill="#EFE8D4"/>
    <circle cx="314.899" cy="625.416" r="6.94378" fill="#EFE8D4"/>
    <circle cx="314.899" cy="657.821" r="6.94378" fill="#EFE8D4"/>
    <circle cx="314.899" cy="690.225" r="6.94378" fill="#EFE8D4"/>
    <circle cx="314.899" cy="722.629" r="6.94378" fill="#EFE8D4"/>
    <circle cx="314.899" cy="755.034" r="6.94378" fill="#EFE8D4"/>
    <circle cx="314.899" cy="787.438" r="6.94378" fill="#EFE8D4"/>
    <circle cx="314.899" cy="819.842" r="6.94378" fill="#EFE8D4"/>
    <circle cx="314.899" cy="852.247" r="6.94378" fill="#EFE8D4"/>
    <circle cx="314.899" cy="884.653" r="6.94378" fill="#EFE8D4"/>
    <circle cx="314.899" cy="917.055" r="6.94378" fill="#EFE8D4"/>
    <circle cx="347.303" cy="625.416" r="6.94378" fill="#EFE8D4"/>
    <circle cx="347.303" cy="657.821" r="6.94378" fill="#EFE8D4"/>
    <circle cx="347.303" cy="690.225" r="6.94378" fill="#EFE8D4"/>
    <circle cx="347.303" cy="722.629" r="6.94378" fill="#EFE8D4"/>
    <circle cx="347.303" cy="755.034" r="6.94378" fill="#EFE8D4"/>
    <circle cx="347.303" cy="787.438" r="6.94378" fill="#EFE8D4"/>
    <circle cx="347.303" cy="819.842" r="6.94378" fill="#EFE8D4"/>
    <circle cx="347.303" cy="852.247" r="6.94378" fill="#EFE8D4"/>
    <circle cx="347.303" cy="884.653" r="6.94378" fill="#EFE8D4"/>
    <circle cx="347.303" cy="917.055" r="6.94378" fill="#EFE8D4"/>
    <circle cx="379.707" cy="625.416" r="6.94378" fill="#EFE8D4"/>
    <circle cx="379.707" cy="657.821" r="6.94378" fill="#EFE8D4"/>
    <circle cx="379.707" cy="690.225" r="6.94378" fill="#EFE8D4"/>
    <circle cx="379.707" cy="722.629" r="6.94378" fill="#EFE8D4"/>
    <circle cx="379.707" cy="755.034" r="6.94378" fill="#EFE8D4"/>
    <circle cx="379.707" cy="787.438" r="6.94378" fill="#EFE8D4"/>
    <circle cx="379.707" cy="819.842" r="6.94378" fill="#EFE8D4"/>
    <circle cx="379.707" cy="852.247" r="6.94378" fill="#EFE8D4"/>
    <circle cx="379.707" cy="884.653" r="6.94378" fill="#EFE8D4"/>
    <circle cx="379.707" cy="917.055" r="6.94378" fill="#EFE8D4"/>
    <circle cx="412.112" cy="625.416" r="6.94378" fill="#EFE8D4"/>
    <circle cx="412.112" cy="657.821" r="6.94378" fill="#EFE8D4"/>
    <circle cx="412.112" cy="690.225" r="6.94378" fill="#EFE8D4"/>
    <circle cx="412.112" cy="722.629" r="6.94378" fill="#EFE8D4"/>
    <circle cx="412.112" cy="755.034" r="6.94378" fill="#EFE8D4"/>
    <circle cx="412.112" cy="787.438" r="6.94378" fill="#EFE8D4"/>
    <circle cx="412.112" cy="819.842" r="6.94378" fill="#EFE8D4"/>
    <circle cx="412.112" cy="852.247" r="6.94378" fill="#EFE8D4"/>
    <circle cx="412.112" cy="884.653" r="6.94378" fill="#EFE8D4"/>
    <circle cx="412.112" cy="917.055" r="6.94378" fill="#EFE8D4"/>
    <circle cx="444.516" cy="625.416" r="6.94378" fill="#EFE8D4"/>
    <circle cx="444.516" cy="657.821" r="6.94378" fill="#EFE8D4"/>
    <circle cx="444.516" cy="690.225" r="6.94378" fill="#EFE8D4"/>
    <circle cx="444.516" cy="722.629" r="6.94378" fill="#EFE8D4"/>
    <circle cx="444.516" cy="755.034" r="6.94378" fill="#EFE8D4"/>
    <circle cx="444.516" cy="787.438" r="6.94378" fill="#EFE8D4"/>
    <circle cx="444.516" cy="819.842" r="6.94378" fill="#EFE8D4"/>
    <circle cx="444.516" cy="852.247" r="6.94378" fill="#EFE8D4"/>
    <circle cx="444.516" cy="884.653" r="6.94378" fill="#EFE8D4"/>
    <circle cx="444.516" cy="917.055" r="6.94378" fill="#EFE8D4"/>
    <circle cx="476.92" cy="625.416" r="6.94378" fill="#EFE8D4"/>
    <circle cx="476.92" cy="657.821" r="6.94378" fill="#EFE8D4"/>
    <circle cx="476.92" cy="690.225" r="6.94378" fill="#EFE8D4"/>
    <circle cx="476.92" cy="722.629" r="6.94378" fill="#EFE8D4"/>
    <circle cx="476.92" cy="755.034" r="6.94378" fill="#EFE8D4"/>
    <circle cx="476.92" cy="787.438" r="6.94378" fill="#EFE8D4"/>
    <circle cx="476.92" cy="819.842" r="6.94378" fill="#EFE8D4"/>
    <circle cx="476.92" cy="852.247" r="6.94378" fill="#EFE8D4"/>
    <circle cx="476.92" cy="884.653" r="6.94378" fill="#EFE8D4"/>
    <circle cx="476.92" cy="917.055" r="6.94378" fill="#EFE8D4"/>
    <circle cx="944.934" cy="748.091" r="6.94378" transform="rotate(-90 944.934 748.091)" fill="#EFE8D4"/>
    <circle cx="977.338" cy="748.091" r="6.94378" transform="rotate(-90 977.338 748.091)" fill="#EFE8D4"/>
    <circle cx="1009.74" cy="748.091" r="6.94378" transform="rotate(-90 1009.74 748.091)" fill="#EFE8D4"/>
    <circle cx="1042.15" cy="748.091" r="6.94378" transform="rotate(-90 1042.15 748.091)" fill="#EFE8D4"/>
    <circle cx="1074.55" cy="748.091" r="6.94378" transform="rotate(-90 1074.55 748.091)" fill="#EFE8D4"/>
    <circle cx="1106.96" cy="748.091" r="6.94378" transform="rotate(-90 1106.96 748.091)" fill="#EFE8D4"/>
    <circle cx="1139.36" cy="748.091" r="6.94378" transform="rotate(-90 1139.36 748.091)" fill="#EFE8D4"/>
    <circle cx="1171.76" cy="748.091" r="6.94378" transform="rotate(-90 1171.76 748.091)" fill="#EFE8D4"/>
    <circle cx="1204.17" cy="748.091" r="6.94378" transform="rotate(-90 1204.17 748.091)" fill="#EFE8D4"/>
    <circle cx="1236.57" cy="748.091" r="6.94378" transform="rotate(-90 1236.57 748.091)" fill="#EFE8D4"/>
    <circle cx="944.934" cy="715.687" r="6.94378" transform="rotate(-90 944.934 715.687)" fill="#EFE8D4"/>
    <circle cx="977.338" cy="715.687" r="6.94378" transform="rotate(-90 977.338 715.687)" fill="#EFE8D4"/>
    <circle cx="1009.74" cy="715.687" r="6.94378" transform="rotate(-90 1009.74 715.687)" fill="#EFE8D4"/>
    <circle cx="1042.15" cy="715.687" r="6.94378" transform="rotate(-90 1042.15 715.687)" fill="#EFE8D4"/>
    <circle cx="1074.55" cy="715.687" r="6.94378" transform="rotate(-90 1074.55 715.687)" fill="#EFE8D4"/>
    <circle cx="1106.96" cy="715.687" r="6.94378" transform="rotate(-90 1106.96 715.687)" fill="#EFE8D4"/>
    <circle cx="1139.36" cy="715.687" r="6.94378" transform="rotate(-90 1139.36 715.687)" fill="#EFE8D4"/>
    <circle cx="1171.76" cy="715.687" r="6.94378" transform="rotate(-90 1171.76 715.687)" fill="#EFE8D4"/>
    <circle cx="1204.17" cy="715.687" r="6.94378" transform="rotate(-90 1204.17 715.687)" fill="#EFE8D4"/>
    <circle cx="1236.57" cy="715.687" r="6.94378" transform="rotate(-90 1236.57 715.687)" fill="#EFE8D4"/>
    <circle cx="944.934" cy="683.283" r="6.94378" transform="rotate(-90 944.934 683.283)" fill="#EFE8D4"/>
    <circle cx="977.338" cy="683.283" r="6.94378" transform="rotate(-90 977.338 683.283)" fill="#EFE8D4"/>
    <circle cx="1009.74" cy="683.283" r="6.94378" transform="rotate(-90 1009.74 683.283)" fill="#EFE8D4"/>
    <circle cx="1042.15" cy="683.283" r="6.94378" transform="rotate(-90 1042.15 683.283)" fill="#EFE8D4"/>
    <circle cx="1074.55" cy="683.283" r="6.94378" transform="rotate(-90 1074.55 683.283)" fill="#EFE8D4"/>
    <circle cx="1106.96" cy="683.283" r="6.94378" transform="rotate(-90 1106.96 683.283)" fill="#EFE8D4"/>
    <circle cx="1139.36" cy="683.283" r="6.94378" transform="rotate(-90 1139.36 683.283)" fill="#EFE8D4"/>
    <circle cx="1171.76" cy="683.283" r="6.94378" transform="rotate(-90 1171.76 683.283)" fill="#EFE8D4"/>
    <circle cx="1204.17" cy="683.283" r="6.94378" transform="rotate(-90 1204.17 683.283)" fill="#EFE8D4"/>
    <circle cx="1236.57" cy="683.283" r="6.94378" transform="rotate(-90 1236.57 683.283)" fill="#EFE8D4"/>
    <circle cx="944.934" cy="650.878" r="6.94378" transform="rotate(-90 944.934 650.878)" fill="#EFE8D4"/>
    <circle cx="977.338" cy="650.878" r="6.94378" transform="rotate(-90 977.338 650.878)" fill="#EFE8D4"/>
    <circle cx="1009.74" cy="650.878" r="6.94378" transform="rotate(-90 1009.74 650.878)" fill="#EFE8D4"/>
    <circle cx="1042.15" cy="650.878" r="6.94378" transform="rotate(-90 1042.15 650.878)" fill="#EFE8D4"/>
    <circle cx="1074.55" cy="650.878" r="6.94378" transform="rotate(-90 1074.55 650.878)" fill="#EFE8D4"/>
    <circle cx="1106.96" cy="650.878" r="6.94378" transform="rotate(-90 1106.96 650.878)" fill="#EFE8D4"/>
    <circle cx="1139.36" cy="650.878" r="6.94378" transform="rotate(-90 1139.36 650.878)" fill="#EFE8D4"/>
    <circle cx="1171.76" cy="650.878" r="6.94378" transform="rotate(-90 1171.76 650.878)" fill="#EFE8D4"/>
    <circle cx="1204.17" cy="650.878" r="6.94378" transform="rotate(-90 1204.17 650.878)" fill="#EFE8D4"/>
    <circle cx="1236.57" cy="650.878" r="6.94378" transform="rotate(-90 1236.57 650.878)" fill="#EFE8D4"/>
    <circle cx="944.934" cy="618.474" r="6.94378" transform="rotate(-90 944.934 618.474)" fill="#EFE8D4"/>
    <circle cx="977.338" cy="618.474" r="6.94378" transform="rotate(-90 977.338 618.474)" fill="#EFE8D4"/>
    <circle cx="1009.74" cy="618.474" r="6.94378" transform="rotate(-90 1009.74 618.474)" fill="#EFE8D4"/>
    <circle cx="1042.15" cy="618.474" r="6.94378" transform="rotate(-90 1042.15 618.474)" fill="#EFE8D4"/>
    <circle cx="1074.55" cy="618.474" r="6.94378" transform="rotate(-90 1074.55 618.474)" fill="#EFE8D4"/>
    <circle cx="1106.96" cy="618.474" r="6.94378" transform="rotate(-90 1106.96 618.474)" fill="#EFE8D4"/>
    <circle cx="1139.36" cy="618.474" r="6.94378" transform="rotate(-90 1139.36 618.474)" fill="#EFE8D4"/>
    <circle cx="1171.76" cy="618.474" r="6.94378" transform="rotate(-90 1171.76 618.474)" fill="#EFE8D4"/>
    <circle cx="1204.17" cy="618.474" r="6.94378" transform="rotate(-90 1204.17 618.474)" fill="#EFE8D4"/>
    <circle cx="1236.57" cy="618.474" r="6.94378" transform="rotate(-90 1236.57 618.474)" fill="#EFE8D4"/>
    <circle cx="944.934" cy="586.07" r="6.94378" transform="rotate(-90 944.934 586.07)" fill="#EFE8D4"/>
    <circle cx="977.338" cy="586.07" r="6.94378" transform="rotate(-90 977.338 586.07)" fill="#EFE8D4"/>
    <circle cx="1009.74" cy="586.07" r="6.94378" transform="rotate(-90 1009.74 586.07)" fill="#EFE8D4"/>
    <circle cx="1042.15" cy="586.07" r="6.94378" transform="rotate(-90 1042.15 586.07)" fill="#EFE8D4"/>
    <circle cx="1074.55" cy="586.07" r="6.94378" transform="rotate(-90 1074.55 586.07)" fill="#EFE8D4"/>
    <circle cx="1106.96" cy="586.07" r="6.94378" transform="rotate(-90 1106.96 586.07)" fill="#EFE8D4"/>
    <circle cx="1139.36" cy="586.07" r="6.94378" transform="rotate(-90 1139.36 586.07)" fill="#EFE8D4"/>
    <circle cx="1171.76" cy="586.07" r="6.94378" transform="rotate(-90 1171.76 586.07)" fill="#EFE8D4"/>
    <circle cx="1204.17" cy="586.07" r="6.94378" transform="rotate(-90 1204.17 586.07)" fill="#EFE8D4"/>
    <circle cx="1236.57" cy="586.07" r="6.94378" transform="rotate(-90 1236.57 586.07)" fill="#EFE8D4"/>
    <circle cx="944.934" cy="553.666" r="6.94378" transform="rotate(-90 944.934 553.666)" fill="#EFE8D4"/>
    <circle cx="977.338" cy="553.666" r="6.94378" transform="rotate(-90 977.338 553.666)" fill="#EFE8D4"/>
    <circle cx="1009.74" cy="553.666" r="6.94378" transform="rotate(-90 1009.74 553.666)" fill="#EFE8D4"/>
    <circle cx="1042.15" cy="553.666" r="6.94378" transform="rotate(-90 1042.15 553.666)" fill="#EFE8D4"/>
    <circle cx="1074.55" cy="553.666" r="6.94378" transform="rotate(-90 1074.55 553.666)" fill="#EFE8D4"/>
    <circle cx="1106.96" cy="553.666" r="6.94378" transform="rotate(-90 1106.96 553.666)" fill="#EFE8D4"/>
    <circle cx="1139.36" cy="553.666" r="6.94378" transform="rotate(-90 1139.36 553.666)" fill="#EFE8D4"/>
    <circle cx="1171.76" cy="553.666" r="6.94378" transform="rotate(-90 1171.76 553.666)" fill="#EFE8D4"/>
    <circle cx="1204.17" cy="553.666" r="6.94378" transform="rotate(-90 1204.17 553.666)" fill="#EFE8D4"/>
    <circle cx="1236.57" cy="553.666" r="6.94378" transform="rotate(-90 1236.57 553.666)" fill="#EFE8D4"/>
    <circle cx="944.934" cy="521.261" r="6.94378" transform="rotate(-90 944.934 521.261)" fill="#EFE8D4"/>
    <circle cx="977.338" cy="521.261" r="6.94378" transform="rotate(-90 977.338 521.261)" fill="#EFE8D4"/>
    <circle cx="1009.74" cy="521.261" r="6.94378" transform="rotate(-90 1009.74 521.261)" fill="#EFE8D4"/>
    <circle cx="1042.15" cy="521.261" r="6.94378" transform="rotate(-90 1042.15 521.261)" fill="#EFE8D4"/>
    <circle cx="1074.55" cy="521.261" r="6.94378" transform="rotate(-90 1074.55 521.261)" fill="#EFE8D4"/>
    <circle cx="1106.96" cy="521.261" r="6.94378" transform="rotate(-90 1106.96 521.261)" fill="#EFE8D4"/>
    <circle cx="1139.36" cy="521.261" r="6.94378" transform="rotate(-90 1139.36 521.261)" fill="#EFE8D4"/>
    <circle cx="1171.76" cy="521.261" r="6.94378" transform="rotate(-90 1171.76 521.261)" fill="#EFE8D4"/>
    <circle cx="1204.17" cy="521.261" r="6.94378" transform="rotate(-90 1204.17 521.261)" fill="#EFE8D4"/>
    <circle cx="1236.57" cy="521.261" r="6.94378" transform="rotate(-90 1236.57 521.261)" fill="#EFE8D4"/>
    <circle cx="944.934" cy="488.855" r="6.94378" transform="rotate(-90 944.934 488.855)" fill="#EFE8D4"/>
    <circle cx="977.338" cy="488.855" r="6.94378" transform="rotate(-90 977.338 488.855)" fill="#EFE8D4"/>
    <circle cx="1009.74" cy="488.855" r="6.94378" transform="rotate(-90 1009.74 488.855)" fill="#EFE8D4"/>
    <circle cx="1042.15" cy="488.855" r="6.94378" transform="rotate(-90 1042.15 488.855)" fill="#EFE8D4"/>
    <circle cx="1074.55" cy="488.855" r="6.94378" transform="rotate(-90 1074.55 488.855)" fill="#EFE8D4"/>
    <circle cx="1106.96" cy="488.855" r="6.94378" transform="rotate(-90 1106.96 488.855)" fill="#EFE8D4"/>
    <circle cx="1139.36" cy="488.855" r="6.94378" transform="rotate(-90 1139.36 488.855)" fill="#EFE8D4"/>
    <circle cx="1171.76" cy="488.855" r="6.94378" transform="rotate(-90 1171.76 488.855)" fill="#EFE8D4"/>
    <circle cx="1204.17" cy="488.855" r="6.94378" transform="rotate(-90 1204.17 488.855)" fill="#EFE8D4"/>
    <circle cx="1236.57" cy="488.855" r="6.94378" transform="rotate(-90 1236.57 488.855)" fill="#EFE8D4"/>
    <circle cx="944.934" cy="456.453" r="6.94378" transform="rotate(-90 944.934 456.453)" fill="#EFE8D4"/>
    <circle cx="977.338" cy="456.453" r="6.94378" transform="rotate(-90 977.338 456.453)" fill="#EFE8D4"/>
    <circle cx="1009.74" cy="456.453" r="6.94378" transform="rotate(-90 1009.74 456.453)" fill="#EFE8D4"/>
    <circle cx="1042.15" cy="456.453" r="6.94378" transform="rotate(-90 1042.15 456.453)" fill="#EFE8D4"/>
    <circle cx="1074.55" cy="456.453" r="6.94378" transform="rotate(-90 1074.55 456.453)" fill="#EFE8D4"/>
    <circle cx="1106.96" cy="456.453" r="6.94378" transform="rotate(-90 1106.96 456.453)" fill="#EFE8D4"/>
    <circle cx="1139.36" cy="456.453" r="6.94378" transform="rotate(-90 1139.36 456.453)" fill="#EFE8D4"/>
    <circle cx="1171.76" cy="456.453" r="6.94378" transform="rotate(-90 1171.76 456.453)" fill="#EFE8D4"/>
    <circle cx="1204.17" cy="456.453" r="6.94378" transform="rotate(-90 1204.17 456.453)" fill="#EFE8D4"/>
    <circle cx="1236.57" cy="456.453" r="6.94378" transform="rotate(-90 1236.57 456.453)" fill="#EFE8D4"/>
    <circle cx="944.934" cy="424.048" r="6.94378" transform="rotate(-90 944.934 424.048)" fill="#EFE8D4"/>
    <circle cx="977.338" cy="424.048" r="6.94378" transform="rotate(-90 977.338 424.048)" fill="#EFE8D4"/>
    <circle cx="1009.74" cy="424.048" r="6.94378" transform="rotate(-90 1009.74 424.048)" fill="#EFE8D4"/>
    <circle cx="1042.15" cy="424.048" r="6.94378" transform="rotate(-90 1042.15 424.048)" fill="#EFE8D4"/>
    <circle cx="1074.55" cy="424.048" r="6.94378" transform="rotate(-90 1074.55 424.048)" fill="#EFE8D4"/>
    <circle cx="1106.96" cy="424.048" r="6.94378" transform="rotate(-90 1106.96 424.048)" fill="#EFE8D4"/>
    <circle cx="1139.36" cy="424.048" r="6.94378" transform="rotate(-90 1139.36 424.048)" fill="#EFE8D4"/>
    <circle cx="1171.76" cy="424.048" r="6.94378" transform="rotate(-90 1171.76 424.048)" fill="#EFE8D4"/>
    <circle cx="1204.17" cy="424.048" r="6.94378" transform="rotate(-90 1204.17 424.048)" fill="#EFE8D4"/>
    <circle cx="1236.57" cy="424.048" r="6.94378" transform="rotate(-90 1236.57 424.048)" fill="#EFE8D4"/>
    <circle cx="944.934" cy="391.644" r="6.94378" transform="rotate(-90 944.934 391.644)" fill="#EFE8D4"/>
    <circle cx="977.338" cy="391.644" r="6.94378" transform="rotate(-90 977.338 391.644)" fill="#EFE8D4"/>
    <circle cx="1009.74" cy="391.644" r="6.94378" transform="rotate(-90 1009.74 391.644)" fill="#EFE8D4"/>
    <circle cx="1042.15" cy="391.644" r="6.94378" transform="rotate(-90 1042.15 391.644)" fill="#EFE8D4"/>
    <circle cx="1074.55" cy="391.644" r="6.94378" transform="rotate(-90 1074.55 391.644)" fill="#EFE8D4"/>
    <circle cx="1106.96" cy="391.644" r="6.94378" transform="rotate(-90 1106.96 391.644)" fill="#EFE8D4"/>
    <circle cx="1139.36" cy="391.644" r="6.94378" transform="rotate(-90 1139.36 391.644)" fill="#EFE8D4"/>
    <circle cx="1171.76" cy="391.644" r="6.94378" transform="rotate(-90 1171.76 391.644)" fill="#EFE8D4"/>
    <circle cx="1204.17" cy="391.644" r="6.94378" transform="rotate(-90 1204.17 391.644)" fill="#EFE8D4"/>
    <circle cx="1236.57" cy="391.644" r="6.94378" transform="rotate(-90 1236.57 391.644)" fill="#EFE8D4"/>
    <circle cx="944.934" cy="359.24" r="6.94378" transform="rotate(-90 944.934 359.24)" fill="#EFE8D4"/>
    <circle cx="977.338" cy="359.24" r="6.94378" transform="rotate(-90 977.338 359.24)" fill="#EFE8D4"/>
    <circle cx="1009.74" cy="359.24" r="6.94378" transform="rotate(-90 1009.74 359.24)" fill="#EFE8D4"/>
    <circle cx="1042.15" cy="359.24" r="6.94378" transform="rotate(-90 1042.15 359.24)" fill="#EFE8D4"/>
    <circle cx="1074.55" cy="359.24" r="6.94378" transform="rotate(-90 1074.55 359.24)" fill="#EFE8D4"/>
    <circle cx="1106.96" cy="359.24" r="6.94378" transform="rotate(-90 1106.96 359.24)" fill="#EFE8D4"/>
    <circle cx="1139.36" cy="359.24" r="6.94378" transform="rotate(-90 1139.36 359.24)" fill="#EFE8D4"/>
    <circle cx="1171.76" cy="359.24" r="6.94378" transform="rotate(-90 1171.76 359.24)" fill="#EFE8D4"/>
    <circle cx="1204.17" cy="359.24" r="6.94378" transform="rotate(-90 1204.17 359.24)" fill="#EFE8D4"/>
    <circle cx="1236.57" cy="359.24" r="6.94378" transform="rotate(-90 1236.57 359.24)" fill="#EFE8D4"/>
    <circle cx="944.934" cy="326.836" r="6.94378" transform="rotate(-90 944.934 326.836)" fill="#EFE8D4"/>
    <circle cx="977.338" cy="326.836" r="6.94378" transform="rotate(-90 977.338 326.836)" fill="#EFE8D4"/>
    <circle cx="1009.74" cy="326.836" r="6.94378" transform="rotate(-90 1009.74 326.836)" fill="#EFE8D4"/>
    <circle cx="1042.15" cy="326.836" r="6.94378" transform="rotate(-90 1042.15 326.836)" fill="#EFE8D4"/>
    <circle cx="1074.55" cy="326.836" r="6.94378" transform="rotate(-90 1074.55 326.836)" fill="#EFE8D4"/>
    <circle cx="1106.96" cy="326.836" r="6.94378" transform="rotate(-90 1106.96 326.836)" fill="#EFE8D4"/>
    <circle cx="1139.36" cy="326.836" r="6.94378" transform="rotate(-90 1139.36 326.836)" fill="#EFE8D4"/>
    <circle cx="1171.76" cy="326.836" r="6.94378" transform="rotate(-90 1171.76 326.836)" fill="#EFE8D4"/>
    <circle cx="1204.17" cy="326.836" r="6.94378" transform="rotate(-90 1204.17 326.836)" fill="#EFE8D4"/>
    <circle cx="1236.57" cy="326.836" r="6.94378" transform="rotate(-90 1236.57 326.836)" fill="#EFE8D4"/>
    <circle cx="944.934" cy="294.431" r="6.94378" transform="rotate(-90 944.934 294.431)" fill="#EFE8D4"/>
    <circle cx="977.338" cy="294.431" r="6.94378" transform="rotate(-90 977.338 294.431)" fill="#EFE8D4"/>
    <circle cx="1009.74" cy="294.431" r="6.94378" transform="rotate(-90 1009.74 294.431)" fill="#EFE8D4"/>
    <circle cx="1042.15" cy="294.431" r="6.94378" transform="rotate(-90 1042.15 294.431)" fill="#EFE8D4"/>
    <circle cx="1074.55" cy="294.431" r="6.94378" transform="rotate(-90 1074.55 294.431)" fill="#EFE8D4"/>
    <circle cx="1106.96" cy="294.431" r="6.94378" transform="rotate(-90 1106.96 294.431)" fill="#EFE8D4"/>
    <circle cx="1139.36" cy="294.431" r="6.94378" transform="rotate(-90 1139.36 294.431)" fill="#EFE8D4"/>
    <circle cx="1171.76" cy="294.431" r="6.94378" transform="rotate(-90 1171.76 294.431)" fill="#EFE8D4"/>
    <circle cx="1204.17" cy="294.431" r="6.94378" transform="rotate(-90 1204.17 294.431)" fill="#EFE8D4"/>
    <circle cx="1236.57" cy="294.431" r="6.94378" transform="rotate(-90 1236.57 294.431)" fill="#EFE8D4"/>
    <path d="M1145.61 25.3025L1214.33 43.714L1164.03 94.0153L1145.61 25.3025Z" fill="#EFE8D4"/>
    <circle cx="933.821" cy="832.343" r="46.2919" fill="#EFE8D4"/>
  </svg>
</template>

<script>
export default {
  name: 'BackgroundShape',
}
</script>
