<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.3333 26.2H14.3333V19H12.6667V26.2H7.66667V19H6V26.2C6 26.6774 6.17559 27.1352 6.48816 27.4728C6.80072 27.8104 7.22464 28 7.66667 28H24.3333C24.7754 28 25.1993 27.8104 25.5118 27.4728C25.8244 27.1352 26 26.6774 26 26.2V19H24.3333V26.2Z"
      fill="currentColor"
    />
    <path
      d="M29.8162 12.0443L26.2462 4.96324C26.1014 4.67408 25.878 4.43074 25.6013 4.26058C25.3246 4.09041 25.0056 4.00017 24.68 4H7.32001C6.99444 4.00017 6.67537 4.09041 6.39867 4.26058C6.12198 4.43074 5.89864 4.67408 5.75376 4.96324L2.18376 12.0443C2.06187 12.2869 1.99894 12.5543 2.00001 12.8253V15.4981C1.99921 15.9036 2.14162 16.2966 2.40251 16.6089C2.78809 17.0472 3.2643 17.3981 3.79878 17.6377C4.33326 17.8772 4.91348 17.9999 5.50001 17.9973C6.45706 17.9988 7.38492 17.6706 8.12501 17.0688C8.86509 17.6709 9.79275 18 10.75 18C11.7073 18 12.6349 17.6709 13.375 17.0688C14.1151 17.6709 15.0427 18 16 18C16.9573 18 17.8849 17.6709 18.625 17.0688C19.3651 17.6709 20.2927 18 21.25 18C22.2073 18 23.1349 17.6709 23.875 17.0688C24.7011 17.7415 25.7581 18.0704 26.8236 17.9862C27.8892 17.9021 28.8804 17.4115 29.5887 16.6175C29.8528 16.3065 29.9983 15.9134 30 15.5068V12.8253C30.0011 12.5543 29.9381 12.2869 29.8162 12.0443ZM13.375 13.0249V14.344L12.6575 15.2812C12.4372 15.5789 12.1494 15.821 11.8172 15.9879C11.4851 16.1548 11.118 16.2417 10.7456 16.2417C10.3733 16.2417 10.0062 16.1548 9.67403 15.9879C9.34187 15.821 9.05401 15.5789 8.83376 15.2812L8.12501 14.3092V13.0249L10.3913 5.73556H14.25L13.375 13.0249ZM23.875 14.3092L23.1662 15.2812C22.946 15.5789 22.6581 15.821 22.326 15.9879C21.9938 16.1548 21.6267 16.2417 21.2544 16.2417C20.882 16.2417 20.5149 16.1548 20.1828 15.9879C19.8506 15.821 19.5628 15.5789 19.3425 15.2812L18.625 14.3092V13.0249L17.75 5.73556H21.6437L23.875 13.0249V14.3092Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "StoreIcon",
};
</script>
