import { render, staticRenderFns } from "./LightIcon.vue?vue&type=template&id=aa1eb82a&"
import script from "./LightIcon.vue?vue&type=script&lang=js&"
export * from "./LightIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports